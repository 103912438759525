<template>
  <div class="gr-border container">
    <ValidationObserver ref="form" v-slot="{ valid }">
      <form>
        <fieldset :disabled="isBusy">
          <div class="row">
            <div class="text-center gr-bak col-12">
              <h1 class="dk-blue upper"><strong>High School Chaperone Registration</strong></h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>1. Chaperone Information</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="first-name"><span class="text-danger">*</span>First Name</label>
                  <input type="text" name="first-name" id="first-name" class="form-control" :class="classes" v-model="formData.firstName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="last-name"><span class="text-danger">*</span>Last Name</label>
                  <input type="text" name="last-name" id="last-name" class="form-control" :class="classes" v-model="formData.lastName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="email" rules="required|email" v-slot="{ classes, errors }" v-if="!isEdit">
                <div class="form-group">
                  <label for="email"><span class="text-danger">*</span>Email Address</label>
                  <input type="email" name="email" id="email" class="form-control" :class="classes" v-model="formData.email">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{ required: true, email: true, confirmEmail: '@email'}" v-slot="{ classes, errors }" v-if="!isEdit">
                <div class="form-group">
                  <label for="email_confirm"><span class="text-danger">*</span>Confirm Email Address</label>
                  <input type="email" name="email_confirm" id="email_confirm" v-model="emailConfirm" class="form-control" :class="classes">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="phone-number"><span class="text-danger">*</span>Phone Number</label>
                  <input type="text" name="phone-number" id="phone-number" class="form-control" :class="classes" v-model="formData.phone">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-12">
              <p><strong><span class="text-danger">*</span>Parent/Legal Guardian please view and sign the consent form below:</strong></p>
              <div class="consent-form-container">
                <h3>Consent and Release Form</h3>
                <p>I, <ValidationProvider rules="required" v-slot="{classes}"><input type="text" name="consent-name" id="consent-name" class="form-control consent-content" :class="classes" placeholder="*First and Last Name" v-model="formData.stemDayConsent.chaperoneFullName" /></ValidationProvider>hereby assume all risks in connection with Goodyear’s STEM Career Day and I further release, discharge, and/or otherwise indemnify The Goodyear Tire & Rubber Company and The University of Akron, as well as their officers, employees and volunteers from all claims, judgments, liability by or on behalf of myself for any injury or damage due to my participation in Goodyear’s STEM Career Day, including all risks connected therewith whether foreseen or unforeseen.</p>
                <p>I hereby acknowledge that registration information may be shared with the University of Akron for the purpose of scheduling.</p>
								<p>The Goodyear Tire & Rubber Company and its associated companies and The University of Akron may make or permit use of, and may copyright, for publicity or other business purposes, the photographs or video taken on or prior to Goodyear’s STEM Career Day of me and/or make or permit use of my written or recorded statements, either verbatim or edited or abridged, in letter form or otherwise, all in such form and manner as said The Goodyear Tire & Rubber Company and The University of Akron may desire and either in conjunction with my name or apart from it.</p>
                <p><ValidationProvider rules="required" v-slot="{classes}"><input type="text" name="consent-signature" id="consent-signature" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.chaperoneElSignature" placeholder="*Event Chaperone's Electronic Signature"/></ValidationProvider> <ValidationProvider :rules="{required: true, validDate: 'MM/DD/YYYY'}" v-slot="{classes}"><input type="text" name="consent-date" id="consent-date" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.date" readonly /></ValidationProvider></p>
              </div>
            </div>
          </div>
          <div class="row mt-5" v-if="!isEdit">
            <div class="form-group col-12">
              <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" :sitekey="recaptchaClientKey"></vue-recaptcha>
              <p class="text-danger mt-1">{{invalidField}}</p>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-md-6">
              <button class="btn btn-yellow" v-on:click="register" v-if="!isEdit">SUBMIT REGISTRATION</button>
              <button class="btn btn-yellow" v-on:click="edit" v-if="isEdit">UPDATE REGISTRATION</button>
            </div>
            <div v-if="isBusy" class="col-md-6 align-self-center">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else class="col-md-6 mt-2">
              <p v-if="!valid && showErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import HSChaperoneRegistration from '../models/hs-chaperone-registration';
  import dataService from '../services/data.service';
  import { VueRecaptcha } from 'vue-recaptcha'
  import { config } from '../../config'

  export default {
    props: ['regId'],
    components: { VueRecaptcha },
    computed: {
      recaptchaClientKey: function () {
        return config.RECAPTCHA_CLIENT_KEY;
      }
    },
    data: function() {
      return {
        isBusy: false,
        isEdit: false,
        showErrorMsg: false,
        formData: JSON.parse(JSON.stringify(HSChaperoneRegistration)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        emailConfirm: "",
        invalidField: ''
      };
    },
    async mounted (){
      this.isBusy = true;
      try {
        if(this.$route.params.regId) {
          this.isEdit = true;
          let regData = await dataService.getRegistrationData(this.$route.params.regId);
          this.formData = regData.data.data;
        }

        this.isBusy = false;

      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    methods: {
      onVerify: function (response) {
        if (response) {
          this.invalidField = '';
          this.formData.gResponse = response;
        } 
      },

      register: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        // recaptcha
        if (!this.formData.gResponse || this.formData.gResponse.length === 0) {
          // reCaptcha not verified
          this.isBusy = false;
          this.invalidField = 'Please verify using reCAPTCHA.'
          return;
        }

        try {
          let response = await dataService.createHSChaperoneReg(this.formData);
          if (response.data.code == 0) {
            this.$router.push({ name: 'thank-you', params: {regType: this.formData.regType}});
          } else if (response.data.code == -2) {
            this.$refs.form.setErrors({email: [response.data.message]});
            this.showErrorMsg = true;
          } else {
            console.log(response.data.message);
          }
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          if(error.response && error.response.data) {
						if(error.response.data.code == -4) {
							this.invalidField = error.response.data.message;
							return;
						}
					}
          console.log(error);
          this.$router.push({ name: 'error-500' });
        }
      },

      edit: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        try {
          await dataService.updateHSChaperoneReg(this.$route.params.regId, this.formData);
          this.$router.push({ name: 'thank-you', params: {regType: this.formData.regType, regId: this.$route.params.regId}});
        } catch (error) {
          this.isBusy = false;
          console.log(error);
          this.$router.push({ name: 'error-500' });
        }
      },

    }
  }

</script>