<template>
  <div class="gr-border container">
    <ValidationObserver ref="form" v-slot="{ valid }">
      <form>
        <fieldset>
          <div class="row">
            <div class="text-center gr-bak col-12">
              <h1 class="dk-blue upper"><strong>Middle School STEM Career Day {{$stemYear}}<br/>Consent Form</strong></h1>
              <p>Parent/Legal Guardian please view and sign and submit the consent form below.</p>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <div class="consent-form-container day-of-consent-form-container">
                <h3>Consent and Release Form</h3>
                <p>I, <ValidationProvider :rules="{required}" v-slot="{classes}"><input type="text" name="consent-name" id="consent-name" class="form-control consent-content" :class="classes" placeholder="*Name of Parent/Guardian" v-model="formData.stemDayConsent.parentGuardianName" /></ValidationProvider>, am the 
                <ValidationProvider rules="required" v-slot="{classes}"><input type="text" name="consent-relation" id="consent-relation" class="form-control consent-content" :class="classes" placeholder="*Father, Mother, Custodial Parent, Legal Guardian" v-model="formData.stemDayConsent.relation" /></ValidationProvider> of 
                <ValidationProvider rules="required" v-slot="{classes}"><input type="text" name="consent-student-name" id="consent-student-name" class="form-control consent-content" :class="classes" placeholder="*Student's Name" v-model="formData.stemDayConsent.studentName" /></ValidationProvider>.</p>
                <p>The above-mentioned child is permitted to participate in Goodyear's STEM Career Day. On behalf of my child and myself, I hereby assume all risks in connection with Goodyear's STEM Career Day and I further release, discharge, and/or otherwise indemnify The Goodyear Tire &amp; Rubber Company, Firestone High School, and Litchfield Middle School as well as their officers, employees and volunteers from all claims, judgments, liability by or on behalf of myself and my child for any injury or damage due to the child's participation in Goodyear's STEM Career Day, including all risks connected therewith whether foreseen or unforeseen.</p>
                <p>I hereby acknowledge that all registration information, including my child's name, grade, and school, may be shared with the University of Akron and/or employees and/or representatives of my child's school system for the purpose of event coordination, scheduling, and travel.</p>
                <p>I hereby acknowledge that the materials submitted by my child to The Goodyear Tire &amp; Rubber Company such as (but not limited to) part of an application in the Rube Goldberg Challenge<sup>&trade;</sup> for Goodyear's STEM Career Day, including any video which may include footage of my child, may appear on a Goodyear website or be otherwise distributed in some form of social media.</p>
                <p>I hereby acknowledge that my child is going to be given access to an account on the website for event participants with the opportunity to log in and change the registration information and password.</p>
                <p>The Goodyear Tire &amp; Rubber Company and its associated companies, The University of Akron, Firestone High School, and Litchfield Middle School may make or permit use of, and may copyright, for publicity or other business purposes, the photographs or video taken on or prior to Goodyear's STEM Career Day of me and/or my son/daughter/legal ward and/or make or permit use of my (or their) written or recorded statements, either verbatim or edited or abridged, in letter form or otherwise, all in such form and manner as said The Goodyear Tire &amp; Rubber Company and The University of Akron may desire and either in conjunction with my name or their name or apart from it.</p>
                <p><ValidationProvider rules="required" v-slot="{classes}"><input type="text" name="consent-signature" id="consent-signature" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.parentGuardianElSignature" placeholder="*Parent/Legal Guardian's Electronic Signature"/></ValidationProvider> <ValidationProvider rules="required" v-slot="{classes}"><input type="text" name="consent-date" id="consent-date" class="form-control consent-content" :class="classes" v-model="formData.stemDayConsent.date" readonly /></ValidationProvider></p>
              </div>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-md-6">
              <button class="btn btn-yellow" v-on:click="register">SUBMIT</button>
            </div>
            <div v-if="isBusy" class="col-md-6 align-self-center">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else class="col-md-6 mt-2">
              <p v-if="!valid && showErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import dataService from '../services/data.service'
  import HSDayOfConsent from '../models/hs-day-of-consent'

  export default {
    data: function() {
      return {
        isBusy: false,
        formData: JSON.parse(JSON.stringify(HSDayOfConsent))
      };
    },
    async mounted (){

    },
    methods: {
      register: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        try {
          let response = await dataService.sendDayOfMSConsent(this.formData);
          if (response.data.code == 0) {
            this.$router.push({ name: 'thank-you', params: {regType: this.formData.regType, isContestFormSubmitted: this.isContestFormSubmitted}});
          } else if (response.data.code == -2) {
            this.$refs.form.setErrors({email: [response.data.message]});
            this.showErrorMsg = true;
          } else {
            console.log(response.data.message);
          }
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          console.log(error);
          this.$router.push({ name: 'error-500' });
        }
      },
    }
  }

</script>