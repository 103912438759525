<template>
  <div class="gr-border container">
    <div class="top text-banner gr-bak text-center">
      <h1><strong>Scholarship Application {{$stemYear}}</strong></h1>
    </div>
    <div class="gr-border">
      <ValidationObserver ref="form" v-slot="{ valid }">
        <form>
          <fieldset :disabled="isBusy">
            <div class="row">
              <div class="col-12">The applicant must be an Ohio high school senior enrolled in a public or private school or home schooled.</div>
              <div class="col-12">
                <div class="form-heading dark-bak">
                  <h3>1. Scholarship Type</h3>
                </div>
                <div class="row nested">
                  <div class="col-12">
                    <!-- <label><span class="text-danger">*</span>Select the scholarship(s) that you choose to be considered for:</label> -->
                    <label><span class="text-danger">*</span>Please select below the scholarship fund that you wish to apply for based on your college major:</label>
                    <p><label><em>** Based on the selections made below, you will be considered for the scholarships that you qualify for.</em></label></p>
                    
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="engineeringCreativeAndEssay" true-value="1" false-value="0" v-model="formData.scholarshipType.engineeringCreativeAndEssay">
                      <label class="form-check-label" for="engineeringCreativeAndEssay">Engineering Scholarship Fund (Creative Component + Essay)</label>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="engineeringEssay" true-value="1" false-value="0" v-model="formData.scholarshipType.engineeringEssay">
                      <label class="form-check-label" for="engineeringEssay">Engineering Scholarship Fund (Essay Only)</label>
                    </div>
                    <ValidationProvider name="scholarship type" :rules="{ atLeastOne: [formData.scholarshipType.engineeringCreativeAndEssay,formData.scholarshipType.engineeringEssay,formData.scholarshipType.stem] }" v-slot="{errors}">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="stem" true-value="1" false-value="0" v-model="formData.scholarshipType.stem">
                        <label class="form-check-label" for="stem">STEM Scholarship Fund (Essay Only)</label>
                      </div>
                      <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-heading dark-bak">
                  <h3>2. Personal Information</h3>
                </div>
                <div class="row nested">
                  <div class="col-md-5">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>First Name</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.firstName">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-5">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Last Name</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.lastName">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-2">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Middle Initial</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.middleInitial">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Street Address 1</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.streetAddress">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Street Address 2</label>
                    <input type="text" class="form-control" v-model="formData.streetAddressAdd">
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>City</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.city">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>State</label>
                        <select class="form-control" :class="classes" v-model="formData.stateId">
                          <option value="">-Select-</option>
                          <option v-for="state in states" v-bind:value="state.id" v-bind:key="state.id">
                            {{ state.title }}
                          </option>
                        </select>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Zip Code</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.zip">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider vid="email" rules="required|email" v-slot="{ classes, errors }" v-if="!isEdit">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Email Address</label>
                        <input type="email" class="form-control" :class="classes" v-model="formData.email">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Date of Birth (format mm/dd/yyyy)</label>
                        <datepicker v-model="formData.dateOfBirth" input-class="form-control" :class="classes" format="MM/dd/yyyy" typeable></datepicker>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-heading dark-bak">
                  <h3>3. Educational Background</h3>
                </div>
                <div class="row nested">
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>High School (current)</label>
                        <select class="form-control" :class="classes" v-model="formData.schoolId">
                          <option value="">-Select Option-</option>
                          <option value="other">My school is not listed here. I will enter school in next field.</option>
                          <option v-for="school in schools" v-bind:value="school.id" v-bind:key="school.id">
                            {{ school.title }}
                          </option>
                        </select>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider :rules="{required: formData.schoolId=='other' }" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span v-if="formData.schoolId=='other'" class="text-danger">*</span>High School (if not in previous list)</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.otherSchoolName" :disabled="formData.schoolId !== 'other'">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>High School Street Address</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.schoolStreetAddress">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>High School City</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.schoolCity">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>High School State</label>
                        <select class="form-control" :class="classes" v-model="formData.schoolStateId">
                          <option value="">-Select-</option>
                          <option v-for="state in states" v-bind:value="state.id" v-bind:key="state.id">
                            {{ state.title }}
                          </option>
                        </select>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>High School Zip Code</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.schoolZip">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Years attended: From</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.yearsAttendedFrom">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Years attended: To</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.yearsAttendedTo">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6" v-if="formData.notOn4Scale==0">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Grade Point Average</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.gradePointAverage">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="form-check col-md-6">
                    <input type="checkbox" class="form-check-input" id="notOn4Scale" true-value="1" false-value="0" v-model="formData.notOn4Scale">
                    <label class="form-check-label" for="notOn4Scale">Not on 4.0 Scale</label>
                  </div>
                  <div class="col-md-6" v-if="formData.notOn4Scale==1">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>If not on 4.0 scale, explain</label>
                        <textarea class="form-control" :class="classes" v-model="formData.notOn4ScaleExplanation"></textarea>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-12 mt-3">
                    <label>Extracurricular activities listed by school year. Please also indicate any leadership positions you held, by year.</label>
                    <p><label>Ex: Athletics, Student Government/Council, Art Club, Choir</label></p>
                    <p><label><em>**Volunteer Activities are not required for this scholarship - please do not list them.</em></label></p>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <td>Activity</td>
                          <td>9</td>
                          <td>10</td>
                          <td>11</td>
                          <td>12</td>
                          <td>Leadership Positions (list grades held)</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in formData.activities" v-bind:key="index">
                          <td><input type="text" class="form-control" v-model="item.title"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear9"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear10"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear11"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear12"></td>
                          <td><input type="text" class="form-control" v-model="item.position"></td>
                          <td class="td-center">
                            <span v-if="index > 0">
                              <a v-b-modal="'removeActivityModal-'+index">
                                <b-icon icon="trash" variant="danger"></b-icon>
                              </a>
                              <b-modal :id="'removeActivityModal-'+index" :title="'Delete Activity #'+(index+1)" ok-title="YES" cancel-title="NO" @ok="removeItem(formData.activities, index);" centered no-fade>
                                <p class="my-4">Are you sure you would like to delete Activity (#{{index+1}})</p>
                              </b-modal>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span><input class="btn white-heading lt-bl-bak" type="button" value="Add another activity" @click="addItem(formData.activities, newActivity)"></span>
                  </div>
                  <div class="col-12 mt-3">
                    <p><label>Honors and Awards listed by school year (Ex: Honor Roll, Varsity athletic letter)</label></p>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <td>Honor/Award</td>
                          <td>9</td>
                          <td>10</td>
                          <td>11</td>
                          <td>12</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in formData.awards" v-bind:key="index">
                          <td><input type="text" class="form-control" v-model="item.title"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear9"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear10"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear11"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear12"></td>
                          <td class="td-center">
                            <span v-if="index > 0">
                              <a v-b-modal="'removeAwardModal-'+index">
                                <b-icon icon="trash" variant="danger"></b-icon>
                              </a>
                              <b-modal :id="'removeAwardModal-'+index" :title="'Delete Honor/Award #'+(index+1)" ok-title="YES" cancel-title="NO" @ok="removeItem(formData.awards, index);" centered no-fade>
                                <p class="my-4">Are you sure you would like to delete Honor/Award (#{{index+1}})</p>
                              </b-modal>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span><input class="btn white-heading lt-bl-bak" type="button" value="Add another honor/award" @click="addItem(formData.awards, newAward)"></span>
                  </div>
                  <div class="col-12 mt-3">
                    <p><label>Have you ever attended any other high school, special educational programs (including Goodyear's Engineering/STEM Career Day), camps, or college or university? If so, please list (include dates of attendance):</label></p>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <td>Event Name</td>
                          <td>9</td>
                          <td>10</td>
                          <td>11</td>
                          <td>12</td>
                          <td>Event Date(s)</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in formData.events" v-bind:key="index">
                          <td><input type="text" class="form-control" v-model="item.title"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear9"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear10"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear11"></td>
                          <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear12"></td>
                          <td><datepicker v-model="item.date" input-class="form-control" format="MM/dd/yyyy"></datepicker></td>
                          <td class="td-center">
                            <span v-if="index > 0">
                              <a v-b-modal="'removeEventModal-'+index">
                                <b-icon icon="trash" variant="danger"></b-icon>
                              </a>
                              <b-modal :id="'removeEventModal-'+index" :title="'Delete Event #'+(index+1)" ok-title="YES" cancel-title="NO" @ok="removeItem(formData.events, index);" centered no-fade>
                                <p class="my-4">Are you sure you would like to delete Event (#{{index+1}})</p>
                              </b-modal>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span><input class="btn white-heading lt-bl-bak" type="button" value="Add another event" @click="addItem(formData.events, newEvent)"></span>
                  </div>
                </div>

                <div class="form-heading dark-bak">
                  <h3>4. Future Career Plans</h3>
                </div>
                <div class="row nested">
                  <div class="col-12">
                    <table class="table table-bordered">
                      <tbody>
                        <tr v-for="(item, index) in formData.universityToAttend" v-bind:key="index">
                          <td style="border-right: 0;">
                            <ValidationProvider rules="required" v-slot="{ classes, errors }">
                              <div class="form-group">
                                <label><span class="text-danger">*</span>University/College you plan to attend</label>
                                <input type="text" class="form-control" :class="classes" v-model="item.name">
                                <div class="invalid-feedback">{{ errors[0] }}</div>
                              </div>
                            </ValidationProvider>
                          </td>
                          <td style="border-left: 0; vertical-align: middle;">
                            <strong><span class="text-danger">*</span>Have you been accepted?</strong>
                            <div class="form-check form-check-inline">
                              <input type="radio" class="form-check-input" :name="'parent-attending-' + index" :id="'parent-attending-' + index +'-yes'" v-model="item.isAccepted" value="1">
                              <label class="form-check-label" :for="'parent-attending-' + index +'-yes'">Yes</label>
                            </div>
                            <ValidationProvider rules="required" v-slot="{errors}">
                              <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" :name="'parent-attending-' + index" :id="'parent-attending-' + index +'-no'" v-model="item.isAccepted" value="0">
                                <label class="form-check-label" :for="'parent-attending-' + index +'-no'">No</label>
                              </div>
                              <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                            </ValidationProvider>
                          </td>
                          <td class="td-center">
                            <span v-if="index > 0">
                              <a v-b-modal="'removeUniversityModal-'+index">
                                <b-icon icon="trash" variant="danger"></b-icon>
                              </a>
                              <b-modal :id="'removeUniversityModal-'+index" :title="'Delete University/College #'+(index+1)" ok-title="YES" cancel-title="NO" @ok="removeItem(formData.universityToAttend, index);" centered no-fade>
                                <p class="my-4">Are you sure you would like to delete University/College (#{{index+1}})</p>
                              </b-modal>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span><input class="btn white-heading lt-bl-bak" type="button" value="Add another University/College" @click="addItem(formData.universityToAttend, newUniversityToAttend)"></span>
                  </div>
                  <div class="col-md-6 mt-3">
                    <ValidationProvider :rules="{required: !isEdit, ext: ['pdf','doc','docx','jpeg','jpg','png']}" ref="scoreReportValidation" v-slot="{ errors, validate }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>ACT/SAT Scores (Please attach a photocopy of the official score report):</label>
                        <p><span class="small" v-if="isEdit&&formData.scoreReport">You already uploaded a file: {{formData.scoreReport}}</span></p>
                        <input type="file" ref="scoreReport" @change="validate" accept="image/jpg,image/jpeg,image/png,application/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                        <p><span class="text-danger"><strong>{{ errors[0] }}</strong></span></p>
                        <span class="small">Must be .pdf, .doc, .docx, .jpg or .png</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 mt-3">
                    <ValidationProvider :rules="{required: !isEdit, ext: ['pdf','doc','docx','jpeg','jpg','png']}" ref="letterOfAcceptanceValidation" v-slot="{ errors, validate }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>If you have been accepted, include copy of letter of acceptance with application materials. If not, please provide letter of intent.</label>
                        <p><span class="small" v-if="isEdit&&formData.letterOfAcceptance">You already uploaded a file: {{formData.letterOfAcceptance}}</span></p>
                        <input type="file" ref="letterOfAcceptance" @change="validate" accept="image/jpg,image/jpeg,image/png,application/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                        <p><span class="text-danger"><strong>{{ errors[0] }}</strong></span></p>
                        <span class="small">Must be .pdf, .doc, .docx, .jpg or .png</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-6">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>What Major or Program do you plan on studying?</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.studyingProgram">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="form-heading dark-bak">
                  <h3>5. Employment Experiences</h3>
                </div>
                <div class="row nested">
                  <div class="col-12">
                    <strong><span class="text-danger">*</span>Are you currently employed?</strong>
                    <div class="form-check form-check-inline">
                      <input type="radio" class="form-check-input" name="is-employed" id="is-employed-yes" v-model="formData.isEmployed" value="1">
                      <label class="form-check-label" for="is-employed-yes">Yes</label>
                    </div>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" name="is-employed" id="is-employed-no" v-model="formData.isEmployed" value="0">
                        <label class="form-check-label" for="is-employed-no">No</label>
                      </div>
                      <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                </div>
                <div v-if="formData.isEmployed==1">
                  <div class="row nested">
                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label><span class="text-danger">*</span>Number of hours you work per week</label>
                          <input type="text" class="form-control" :class="classes" v-model="formData.workHoursPerWeek">
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label><span class="text-danger">*</span>Employer</label>
                          <input type="text" class="form-control" :class="classes" v-model="formData.employer">
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label><span class="text-danger">*</span>Type of work</label>
                          <input type="text" class="form-control" :class="classes" v-model="formData.typeOfWork">
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row nested">
                    <div class="col-12">
                      <strong><span class="text-danger">*</span>Past employers? </strong>
                      <div class="form-check form-check-inline">
                        <input type="radio" class="form-check-input" name="has-past-employers" id="has-past-employers-yes" v-model="formData.hasPastEmployers" value="1">
                        <label class="form-check-label" for="has-past-employers-yes">Yes</label>
                      </div>
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <div class="form-check form-check-inline">
                          <input type="radio" class="form-check-input" name="has-past-employers" id="has-past-employers-no" v-model="formData.hasPastEmployers" value="0">
                          <label class="form-check-label" for="has-past-employers-no">No</label>
                        </div>
                        <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div v-if="formData.hasPastEmployers==1">
                    <div class="row nested">
                      <div class="col-12">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <td>Job Title</td>
                              <td>Job Description</td>
                              <td>9</td>
                              <td>10</td>
                              <td>11</td>
                              <td>12</td>
                              <td>Employer</td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in formData.pastEmployers" v-bind:key="index">
                              <td><input type="text" class="form-control" v-model="item.jobTitle" placeholder="Ex: Lifeguard"></td>
                              <td><input type="text" class="form-control" v-model="item.jobDescription" placeholder="Ex: Care for the safety of swimmers"></td>
                              <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear9"></td>
                              <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear10"></td>
                              <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear11"></td>
                              <td class="td-center"><input type="checkbox" true-value="1" false-value="0" v-model="item.schoolYear12"></td>
                              <td><input type="text" class="form-control" v-model="item.employer" placeholder="Ex: YMCA"></td>
                              <td class="td-center">
                                <span v-if="index > 0">
                                  <a v-b-modal="'removeEmployerModal-'+index">
                                    <b-icon icon="trash" variant="danger"></b-icon>
                                  </a>
                                  <b-modal :id="'removeEmployerModal-'+index" :title="'Delete Employer #'+(index+1)" ok-title="YES" cancel-title="NO" @ok="removeItem(formData.pastEmployers, index);" centered no-fade>
                                    <p class="my-4">Are you sure you would like to delete Employer (#{{index+1}})</p>
                                  </b-modal>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <span><input class="btn white-heading lt-bl-bak" type="button" value="Add another employer" @click="addItem(formData.pastEmployers, newPastEmployer)"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-heading dark-bak">
                  <h3>6. Submissions</h3>
                </div>
                <div class="row nested">
                  <div class="col-12">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <p><strong><span class="text-danger">*</span>Essay</strong></p>
                      <p>In 1,000 words or less, please describe how you are pursuing STEM by addressing the following bullet points:</p>
                      <ol>
                        <li>What inspired you to pursue a STEM major?</li>
                        <li>What are your career goals?</li>
                        <li>What are you currently working on that involves STEM or is STEM related?</li>
                      </ol>
                      <div class="form-group col-md-8">
                        <textarea class="form-control" :class="classes" v-model="formData.essay"></textarea>
                        <span class="small float-right"><em>{{essayWordsCounter}} words left</em></span>
                        <div class="invalid-feedback">{{ errors[0] }}</div>                      
                      </div>
                    </ValidationProvider>
                    <div class="form-group col-md-12">
                      <p><strong>Optional "Creative Component"</strong></p>
                      <p>(This qualifies applicants for the $2,500 scholarship - must be an engineering major).</p>
                      <p>Please further explain how you are pursing STEM and bring your essay to life!  Be creative and use any medium you like.</p>
                      <p>You will be deducted points if you use a past school project. Please be creative and show us something original and new</p>
                      <p>Please attach separately with an appropriate title and send with the application and other materials to the e-mail address listed on page 3.  If submitting via a website, please attach complete web address on a separate page and title appropriately. </p>
                      <span class="small"><em>In the case of a video, the applicant must be narrating the video/explaining the work.</em></span>
                      <ValidationProvider rules="ext:pdf,doc,docx,jpeg,jpg,png" ref="creativeComponentValidation" v-slot="{ errors, validate }">
                          <p><span class="small" v-if="isEdit&&formData.creativeComponent">You already uploaded a file: {{formData.creativeComponent}}</span></p>
                          <input type="file" ref="creativeComponent" @change="validate" accept="image/jpg,image/jpeg,image/png,application/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                          <p><span class="text-danger"><strong>{{ errors[0] }}</strong></span></p>
                          <p><span class="small">Note: If submitting a video please enter the URL to your video below.</span></p>
                          <p><span class="small">Must be .pdf, .doc, .docx, .jpg or .png</span></p>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-12">
                      <p>If submitting via a website or video, please enter complete web address below. For video submissions link must be to a public YouTube file.</p>
                      <div class="form-group col-md-6">
                        <label>Website or Video Link</label>
                        <input type="text" class="form-control" v-model="formData.creativeComponentURL">
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <p><strong><span class="text-danger">*</span>Letter of Recommendation - Must have a written signature.</strong></p>
                      <ValidationProvider :rules="{required: !isEdit, ext: ['pdf','doc','docx','jpeg','jpg','png']}" ref="recommendationValidation" v-slot="{ errors, validate }">
                        <p><span class="small" v-if="isEdit&&formData.recommendation">You already uploaded a file: {{formData.recommendation}}</span></p>
                        <input type="file" ref="recommendation" @change="validate" accept="image/jpg,image/jpeg,image/png,application/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
                        <p><span class="text-danger"><strong>{{ errors[0] }}</strong></span></p>
                        <span class="small">Must be .pdf, .doc, .docx, .jpg or .png</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <div class="form-heading dark-bak">
                  <h3>7. Electronic Signature</h3>
                </div>
                <div class="row nested">
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="isInformationFactual" true-value="1" false-value="0" v-model="formData.isInformationFactual">
                      <label class="form-check-label" for="isInformationFactual">By checking this box, I certify that to the best of my knowledge, the information in this application is true and factual.</label>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <ValidationProvider rules="required" v-slot="{ classes, errors }">
                      <div class="form-group">
                        <label><span class="text-danger">*</span>Signature</label>
                        <input type="text" class="form-control" :class="classes" v-model="formData.signature">
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-6 mt-3">
                    <label><span class="text-danger">*</span>Date<span class="text-danger"></span></label>
                    <input type="text" class="form-control" v-model="formData.signatureDate" readonly>
                  </div>
                </div>
                <div class="row nested mt-5" v-if="!isEdit">
                  <div class="form-group col-12">
                    <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" :sitekey="recaptchaClientKey"></vue-recaptcha>
                    <p class="text-danger mt-1">{{invalidField}}</p>
                  </div>
                </div>
                <div class="row nested my-4">
                  <div class="col-md-6">
                    <button class="btn btn-yellow" v-on:click="register" v-if="!isEdit">SUBMIT APPLICATION</button>
                    <button class="btn btn-yellow" v-on:click="edit" v-if="isEdit">UPDATE APPLICATION</button>
                  </div>
                  <div v-if="isBusy" class="col-md-6 align-self-center">
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                  <div v-else class="col-md-6 mt-2">
                    <p v-if="!valid && showErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
                  </div>
                </div>

              </div>
            </div>
          </fieldset>
        </form>
      </ValidationObserver>  
    </div>
  </div>
</template>

<script>
  import { ScholarshipApplication, activityItem, awardItem, eventItem, universityToAttendItem, pastEmployerItem } from '../models/scholarship-application';
  import dataService from '../services/data.service';
  import { states } from '../data/states'
  import { VueRecaptcha } from 'vue-recaptcha'
  import { config } from '../../config'

  export default {
    props: ['regId'],
    components: { VueRecaptcha },
    data: function() {
      return {
        isBusy: false,
        isEdit: false,
        showErrorMsg: false,
        schools: [],
        states: states,
        formData: JSON.parse(JSON.stringify(ScholarshipApplication)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        newActivity: activityItem,
        newAward: awardItem,
        newEvent: eventItem,
        newUniversityToAttend: universityToAttendItem,
        newPastEmployer: pastEmployerItem,
        invalidField: ''
      };
    },

    computed: {
      recaptchaClientKey: function () {
        return config.RECAPTCHA_CLIENT_KEY;
      },

      essayWordsCounter: function () {
        let essayWordsLeft = 1001 - this.formData.essay.split(/\S+/g).length;
        return essayWordsLeft < 0 ? 0 : essayWordsLeft;
      }
    },

    async mounted (){
      this.isBusy = true;
      try {
        if(this.$route.params.regId) {
          this.isEdit = true;
          let regData = await dataService.getRegistrationData(this.$route.params.regId);
          this.formData = regData.data.data;
        }

        let schoolList = await dataService.getCatalogItemsByCategory("HIGHSCH");
        this.schools = schoolList.data.data;

        if(!this.formData.activities.length) {
          this.addItem(this.formData.activities, this.newActivity);
        }
        if(!this.formData.awards.length) {
          this.addItem(this.formData.awards, this.newAward);
        }
        if(!this.formData.events.length) {
          this.addItem(this.formData.events, this.newEvent);
        }
        if(!this.formData.universityToAttend.length) {
          this.addItem(this.formData.universityToAttend, this.newUniversityToAttend);
        }
        if(!this.formData.pastEmployers.length) {
          this.addItem(this.formData.pastEmployers, this.newPastEmployer);
        }

        this.isBusy = false;

      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    methods: {
      onVerify: function (response) {
        if (response) {
          this.invalidField = '';
          this.formData.gResponse = response;
        } 
      },

      register: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        // recaptcha
        if (!this.formData.gResponse || this.formData.gResponse.length === 0) {
          // reCaptcha not verified
          this.isBusy = false;
          this.invalidField = 'Please verify using reCAPTCHA.'
          return;
        }

        this.clearHiddenFields();
        this.setRelatedFields();

        try {
          this.updateModelWithAttachmentsData();
          // need to upload files simultaneously to send attachments in email
          this.formData.attachments = await this.collectAttachmentsData("");
          let response = await dataService.createScholarshipApplication(this.formData);
          if (response.data.code == 0) {
            this.$router.push({ name: 'thank-you', params: { regType: this.formData.regType }});
          } else if (response.data.code == -2) {
            this.$refs.form.setErrors({email: [response.data.message]});
            this.showErrorMsg = true;
          } else {
            console.log(response.data.message);
          }
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          if(error.response && error.response.data) {
						if(error.response.data.code == -4) {
							this.invalidField = error.response.data.message;
							return;
						}
					}
          console.log(error);
          this.$router.push({ name: 'error-500' });
        }
      },

      edit: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        this.clearHiddenFields();
        this.setRelatedFields();
        
        try {
          for (let key in this.$refs) {
            let ref = this.$refs[key];
            if (ref.type=='file' && ref.files.length && this.formData[key]) {
              //replace to batch delete?
              await dataService.deleteFile(this.formData.regId, key.toUpperCase(), this.formData[key]);
            }
          }
          this.updateModelWithAttachmentsData();
          let attachments = await this.collectAttachmentsData(this.$route.params.regId);
          await dataService.uploadFiles(attachments);
          await dataService.updateScholarshipApplication(this.$route.params.regId, this.formData);
          this.isBusy = false;
          this.$router.push({ name: 'thank-you', params: {regType: this.formData.regType, regId: this.$route.params.regId} });
        } catch (error) {
          this.isBusy = false;
          console.log(error);
          this.$router.push({ name: 'error-500' });
        }
      },

      removeItem: function (array, index) {
        array.splice(index, 1);
      },

      addItem: function (array, item) {
        let newItem = JSON.parse(JSON.stringify(item)); // clone object to leave template object intact
        array.push(newItem);
      },

      clearHiddenFields: function () {
        if (this.formData.schoolId != 'other') {
          this.formData.otherSchoolName = "";
        }
        if (this.formData.notOn4Scale == 1) {
          this.formData.gradePointAverage = "";
        } else {
          this.formData.notOn4ScaleExplanation = "";
        }
        if (this.formData.isEmployed != 1) {
          this.formData.workHoursPerWeek = "";
          this.formData.employer = "";
          this.formData.typeOfWork = "";
        }
        if (this.formData.hasPastEmployers !=1) {
          this.formData.pastEmployers = [];
        }
      },

      setRelatedFields: function () {
        this.states.forEach( item => {
          if (item.id == this.formData.stateId) this.formData.stateName = item.title;
          if (item.id == this.formData.schoolStateId) this.formData.schoolStateName = item.title;
        });
        this.schools.forEach( item => {
          if (item.id == this.formData.schoolId) this.formData.schoolName = item.title;
        });
      },

      updateModelWithAttachmentsData: function() {
        for (let key in this.$refs) {
          let ref = this.$refs[key];
          if (ref.type=='file' && ref.files.length) {
            this.formData[key] = ref.files[0].name;
          }
        }
      },

      collectAttachmentsData: async function(regId) {
        let attachments = [];
        for (let key in this.$refs) {
          let ref = this.$refs[key];
          if (ref.type=='file' && ref.files.length) {
            attachments.push({
              regId: regId,
              attachmentType: key.toUpperCase(),
              fileName: ref.files[0].name,
              dataURL: (await dataService.readAsDataURL(ref.files[0])).data,
              type: ref.files[0].type
            });
          }
        }
        return attachments;
      }

    }
  }

</script>