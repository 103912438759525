<template>
  <div class="gr-border container">
    <ValidationObserver ref="form" v-slot="{ valid }">
      <form>
        <fieldset :disabled="isBusy">
          <div class="row">
            <div class="text-center yl-bak col-12">
              <h1 class="dk-blue upper"><strong>Company Registration for STEM Career Day </strong></h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>1. Your Company, University or Organization</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12">
              <p><strong><span class="text-danger">*</span>Which event would you like to register your company for?</strong></p>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="event-choice" id="event-choice-0" v-model="formData.eventChoice" value="0">
                <label class="form-check-label" for="event-choice-0">Middle School Event</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="event-choice" id="event-choice-1" v-model="formData.eventChoice" value="1">
                <label class="form-check-label" for="event-choice-1">High School Event</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="event-choice" id="event-choice-2" v-model="formData.eventChoice" value="2">
                  <label class="form-check-label" for="event-choice-2">Both Middle school and High School Event</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="event-choice" id="event-choice-3" v-model="formData.eventChoice" value="3">
                  <label class="form-check-label" for="event-choice-3">Sponsorship Only</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <p><strong><span class="text-danger">*</span>Select one of the following you represent:</strong></p>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="company-type" id="company-type-0" v-model="formData.companyType" value="0">
                <label class="form-check-label" for="company-type-0">Company</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="company-type" id="company-type-1" v-model="formData.companyType" value="1">
                <label class="form-check-label" for="company-type-1">University</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="company-type" id="company-type-2" v-model="formData.companyType" value="2">
                  <label class="form-check-label" for="company-type-2">Organization</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="company-name"><span class="text-danger">*</span>Name of Company/University/Organization</label>
                  <input type="text" name="company-name" id="company-name" class="form-control" :class="classes" v-model="formData.companyName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="company-address"><span class="text-danger">*</span>Business Street Address</label>
                  <input type="text" name="company-address" id="company-address" class="form-control" :class="classes" v-model="formData.companyAddress">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6 form-group">
              <label for="company-apartment">Apartment or Suite</label>
              <input type="text" name="company-apartment" id="company-apartment" class="form-control" v-model="formData.companyApartment">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="company-city"><span class="text-danger">*</span>City</label>
                  <input type="text" name="company-city" id="company-city" class="form-control" :class="classes" v-model="formData.companyCity">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="state-selector"><span class="text-danger">*</span>State</label>
                  <select id="state-selector" name="state-selector" class="form-control" :class="classes" v-model="formData.companyStateId">
                    <option value="">-Select-</option>
                    <option v-for="state in states" v-bind:value="state.id" v-bind:key="state.id">
                      {{ state.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="company-zip"><span class="text-danger">*</span>Zip Code</label>
                  <input type="text" name="company-zip" id="company-zip" class="form-control" :class="classes" v-model="formData.companyZip">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>2. Main Contact</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="first-name"><span class="text-danger">*</span>Main Contact First Name</label>
                  <input type="text" name="first-name" id="first-name" class="form-control" :class="classes" v-model="formData.firstName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="last-name"><span class="text-danger">*</span>Last Name</label>
                  <input type="text" name="last-name" id="last-name" class="form-control" :class="classes" v-model="formData.lastName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="email" rules="required|email" v-slot="{ classes, errors }" :disabled="isEdit">
                <div class="form-group">
                  <label for="email"><span class="text-danger">*</span>Email Address</label>
                  <input type="text" name="email" id="email" class="form-control" :class="classes" v-model="formData.email" :disabled="isEdit">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="phone"><span class="text-danger">*</span>Work Phone Number</label>
                  <input type="text" name="phone" id="phone" class="form-control" :class="classes" v-model="formData.phone">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="cell-phone"><span class="text-danger">*</span>Cell Phone Number</label>
                  <input type="text" name="cell-phone" id="cell-phone" class="form-control" :class="classes" v-model="formData.cellPhone">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <p><strong><span class="text-danger">*</span>Which field of STEM will you be representing? </strong> (Select all that apply)</p>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="science" true-value="1" false-value="0" v-model="formData.stem.science">
                <label class="form-check-label" for="science">Science</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="technology" true-value="1" false-value="0" v-model="formData.stem.technology">
                <label class="form-check-label" for="technology">Technology</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="engineering" true-value="1" false-value="0" v-model="formData.stem.engineering">
                <label class="form-check-label" for="engineering">Engineering</label>
              </div>
              <ValidationProvider name="subject" :rules="{ atLeastOne: [formData.stem.science,formData.stem.technology,formData.stem.engineering,formData.stem.math] }" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="math" true-value="1" false-value="0" v-model="formData.stem.math">
                  <label class="form-check-label" for="math">Math</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row" v-if="formData.eventChoice=='0' || formData.eventChoice==2">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>3. Middle School Event Preparation</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label><span class="text-danger">*</span>For Middle School STEM Career Day, we will participate in:</label>
                  <select class="form-control" :class="classes" v-model="formData.msEventType">
                    <option value="">-Select-</option>
                    <option value="0">Information Fair</option>
                    <option value="1">Interactive Activity</option>
                    <option value="2">Both</option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-12" v-if="formData.msEventType=='0' || formData.msEventType==2">
              <label>INFORMATION FAIR:</label>
              <div class="card panel-default">
                <div class="card-body">
                  <div class="form-group">
                    <strong><span class="text-danger">*</span>Number of Tables</strong>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="information-fair-tables" id="information-fair-tables-1" v-model="formData.msInformationFair.numberOfTables" value="1">
                      <label class="form-check-label" for="information-fair-tables-1">1</label>
                    </div>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="information-fair-tables" id="information-fair-tables-2" v-model="formData.msInformationFair.numberOfTables" value="2">
                        <label class="form-check-label" for="information-fair-tables-2">2</label>
                      </div>
                      <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <strong><span class="text-danger">*</span>Do you need electricity?</strong>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="information-fair-electricity" id="information-fair-electricity-yes" v-model="formData.msInformationFair.isElectricityNeeded" value="1">
                      <label class="form-check-label" for="information-fair-electricity-yes">Yes</label>
                    </div>
                    <ValidationProvider rules="required" v-slot="{errors}">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="information-fair-electricity" id="information-fair-electricity-no" v-model="formData.msInformationFair.isElectricityNeeded" value="0">
                        <label class="form-check-label" for="information-fair-electricity-no">No</label>
                      </div>
                      <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" v-if="formData.msEventType==1 || formData.msEventType==2">
              <label>INTERACTIVE ACTIVITY:</label>
              <div class="card panel-default">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-12">
                      <ValidationProvider rules="required" v-slot="{classes, errors }">
                        <label><span class="text-danger">*</span>Description of the Interactive Activity you are sponsoring:</label>
                        <textarea class="form-control" :class="classes" v-model="formData.msInteractive.description"></textarea>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label><span class="text-danger">*</span>Classroom Style Preference:</label>
                          <select class="form-control" :class="classes" v-model="formData.msInteractive.classroomStyle">
                            <option value="">-Select-</option>
                            <option value="0">Science Lab</option>
                            <option value="1">Normal Classroom</option>
                          </select>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <strong><span class="text-danger">*</span>Do you need large tables in the classroom?</strong>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="interactive-add-tables" id="interactive-add-tables-yes" v-model="formData.msInteractive.isAdditionalTablesNeeded" value="1">
                          <label class="form-check-label" for="interactive-add-tables-yes">Yes</label>
                        </div>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="interactive-add-tables" id="interactive-add-tables-no" v-model="formData.msInteractive.isAdditionalTablesNeeded" value="0">
                            <label class="form-check-label" for="interactive-add-tables-no">No</label>
                          </div>
                          <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                        </ValidationProvider>
                      </div>
                      <div class="form-group" v-if="formData.msInteractive.isAdditionalTablesNeeded==1">
                        <strong><span class="text-danger">*</span>How many tables?</strong>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="interactive-tables" id="interactive-tables-1" v-model="formData.msInteractive.numberOfTables" value="1">
                          <label class="form-check-label" for="interactive-tables-1">1</label>
                        </div>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="interactive-tables" id="interactive-tables-2" v-model="formData.msInteractive.numberOfTables" value="2">
                            <label class="form-check-label" for="interactive-tables-2">2</label>
                          </div>
                          <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                        </ValidationProvider>
                      </div>
                      <div class="form-group">
                        <strong><span class="text-danger">*</span>Do you need Goodyear to provide a projector?</strong>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="interactive-projector" id="interactive-projector-yes" v-model="formData.msInteractive.isProjectorNeeded" value="1">
                          <label class="form-check-label" for="interactive-projector-yes">Yes</label>
                        </div>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="interactive-projector" id="interactive-projector-no" v-model="formData.msInteractive.isProjectorNeeded" value="0">
                            <label class="form-check-label" for="interactive-projector-no">No</label>
                          </div>
                          <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                        </ValidationProvider>
                      </div>
                      <div class="form-group">
                        <strong><span class="text-danger">*</span>Do you need Internet Access?</strong>
                        <div class="form-check form-check-inline">  
                          <input class="form-check-input" type="radio" name="interactive-internet" id="interactive-internet-yes" v-model="formData.msInteractive.isInternetNeeded" value="1">
                          <label class="form-check-label" for="interactive-internet-yes">Yes</label>
                        </div>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="interactive-internet" id="interactive-internet-no" v-model="formData.msInteractive.isInternetNeeded" value="0">
                            <label class="form-check-label" for="interactive-internet-no">No</label>
                          </div>
                          <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                        </ValidationProvider>
                      </div>
                      <div class="form-group">
                        <strong><span class="text-danger">*</span>Do you need an extension cord?</strong>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="interactive-extension-cord" id="interactive-extension-cord-yes" v-model="formData.msInteractive.isExtensionCordNeeded" value="1">
                          <label class="form-check-label" for="interactive-extension-cord-yes">Yes</label>
                        </div>
                        <ValidationProvider rules="required" v-slot="{errors}">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="interactive-extension-cord" id="interactive-extension-cord-no" v-model="formData.msInteractive.isExtensionCordNeeded" value="0">
                            <label class="form-check-label" for="interactive-extension-cord-no">No</label>
                          </div>
                          <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                        </ValidationProvider>
                      </div>
                      <!-- <div class="form-group">
                        <label>Please describe any cables or cords you need Goodyear to provide:</label>
                        <textarea class="form-control" v-model="formData.msInteractive.cables"></textarea>
                      </div> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <ValidationProvider rules="required" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label><span class="text-danger">*</span>Would you like to have lunch?</label>
                          <select class="form-control" :class="classes" v-model="formData.msInteractive.isLunchNeeded">
                            <option value="">-Select-</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </select>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6" v-if="formData.msInteractive.isLunchNeeded==1">
                      <ValidationProvider rules="required" v-slot="{ classes, errors }">
                        <div class="form-group">
                          <label><span class="text-danger">*</span>Lunch</label>
                          <select class="form-control" :class="classes" v-model="formData.msInteractive.lunchTypeId">
                            <option value="">-Select Option-</option>
                            <option v-for="lunchType in lunchTypes" v-bind:value="lunchType.id" v-bind:key="lunchType.id">
                              {{ lunchType.title }}
                            </option>
                          </select>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="formData.eventChoice==1 || formData.eventChoice==2">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3 v-if="formData.eventChoice==1">3. High School Event Preparation</h3>
                <h3 v-else-if="formData.eventChoice==2">4. High School Event Preparation</h3>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <strong><span class="text-danger">*</span>Number of Tables</strong>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="hs-tables" id="hs-tables-1" v-model="formData.hsNumberOfTables" value="1">
                  <label class="form-check-label" for="hs-tables-1">1</label>
                </div>
                <ValidationProvider rules="required" v-slot="{errors}">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="hs-tables" id="hs-tables-2" v-model="formData.hsNumberOfTables" value="2">
                    <label class="form-check-label" for="hs-tables-2">2</label>
                  </div>
                  <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <strong><span class="text-danger">*</span>Do you need electricity?</strong>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="hs-electricity" id="hs-electricity-yes" v-model="formData.hsIsElectricityNeeded" value="1">
                  <label class="form-check-label" for="hs-electricity-yes">Yes</label>
                </div>
                <ValidationProvider rules="required" v-slot="{errors}">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="hs-electricity" id="hs-electricity-no" v-model="formData.hsIsElectricityNeeded" value="0">
                    <label class="form-check-label" for="hs-electricity-no">No</label>
                  </div>
                  <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3 v-if="formData.eventChoice=='0' || formData.eventChoice==1">4. STEM Career Day Sponsorship</h3>
                <h3 v-else-if="formData.eventChoice==2">5. STEM Career Day Sponsorship</h3>
                <h3 v-else>3. STEM Career Day Sponsorship</h3>
              </div>
            </div>
            <div class="form-group col-12">
              <strong><span class="text-danger">*</span>Would you like to be a sponsor for STEM Career Day {{$stemYear}}?</strong>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="is-sponsor" id="is-sponsor-yes" v-model="formData.isSponsor" value="1">
                <label class="form-check-label" for="is-sponsor-yes">Yes</label>
              </div>
              <ValidationProvider rules="required" v-slot="{errors}">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="is-sponsor" id="is-sponsor-no" v-model="formData.isSponsor" value="0">
                  <label class="form-check-label" for="is-sponsor-no">No</label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row" v-show="formData.isSponsor==1">
            <div class="form-group col-12">
              <p><strong><span class="text-danger">*</span>Sponsorship Type</strong></p>
              <div class="col-12 form-check form-check-inline">
                <input class="form-check-input" type="radio" name="sponsorship-type" id="sponsorship-type-0" v-model="formData.sponsorshipType" value="0">
                <label class="form-check-label" for="sponsorship-type-0">
                  <strong>Blue ($500) - Supports the participation of 15 students to STEM Career Day</strong>
                </label>
              </div>
              <div class="col-12 form-check form-check-inline">
                <input class="form-check-input" type="radio" name="sponsorship-type" id="sponsorship-type-1" v-model="formData.sponsorshipType" value="1">
                <label class="form-check-label" for="sponsorship-type-1">
                  <strong>Gold ($750) - Supports the participation of 25 students to STEM Career Day</strong>
                  <ul>
                    <li>Corporate logo will be displayed on STEM Career Day website</li>
                  </ul>
                </label>
              </div>
              <ValidationProvider :rules="{required: formData.isSponsor==1}" v-slot="{errors}">
                <div class="col-12 form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="sponsorship-type" id="sponsorship-type-2" v-model="formData.sponsorshipType" value="2">
                  <label class="form-check-label" for="sponsorship-type-2">
                    <strong>Wingfoot ($1050) - Supports the participation of 35 students to STEM Career Day</strong>
                    <ul>
                      <li>Corporate logo will be displayed on the STEM Career Day website and Middle School event t-shirts</li>
                      <li>T-shirts will be provided for company representatives at STEM Career Day</li>
                    </ul>
                  </label>
                </div>
                <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
              </ValidationProvider>
            </div>
            <div class="col-md-12"><p>Sponsorship invoice will be sent to main contact after registration. </p></div>
            <div class="col-md-6" v-if="formData.sponsorshipType==2">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label><span class="text-danger">*</span>Main Contact T-Shirt Size</label>
                  <select class="form-control" :class="classes" v-model="formData.tShirtSizeId">
                    <option value="" >-Select Option-</option>
                    <option v-for="tShirtSize in tShirtSizes" v-bind:value="tShirtSize.id" v-bind:key="tShirtSize.id">
                      {{ tShirtSize.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row" v-show="formData.isSponsor==1 && (formData.sponsorshipType==1 || formData.sponsorshipType==2)">
            <div class="form-group col-md-8">
              <p><strong>Upload Logo</strong></p>
              <p>Please upload the company image you would like to have included on sponsorship.</p>
              <div class="card panel-default">
                <ValidationProvider rules="ext:jpeg,jpg,png" ref="attachment" v-slot="{ errors, validate }">
                  <div class="card-body">
                    <img v-show="imageSrc" :src="imageSrc" alt="company logo">
                    <!---
                    <p v-show="formData.companyLogo && !imageSrc"><strong>{{formData.companyLogo}}</strong></p>
                    --->
                    <input size="40" type="file" ref="file" @change="validate;imageSrc=null;" accept="image/jpg,image/jpeg,image/png,application/png">
                    <p><span class="text-danger">{{ errors[0] }}</span></p>
                    <p><span class="small">Please upload a high resolution logo (jpeg, jpg, or png)</span></p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row" v-show="formData.isSponsor==1 && formData.sponsorshipType==2">
            <div class="form-group col-md-8">
              <div class="card panel-default">
                <ValidationProvider rules="ext:eps" ref="attachment_eps" v-slot="{ errors, validate }">
                  <div class="card-body">
                    <p v-show="attachment_eps_label"><strong>{{attachment_eps_label}}</strong></p>
                    <input size="40" type="file" ref="file_eps" @change="validate;attachment_eps_label=null;" accept=".eps">
                    <p><span class="text-danger">{{ errors[0] }}</span></p>
                    <p><span class="small">Please upload a high resolution logo <strong>(.eps version for t-shirt)</strong></span></p>
                    <p><span class="small"><i>*If you do not have an .eps version of your company logo, please contact gystem_careerday@goodyear.com for assistance.</i></span></p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3 v-if="formData.eventChoice=='0' || formData.eventChoice==1">5. Additional Participants</h3>
                <h3 v-else-if="formData.eventChoice==2">6. Additional Participants</h3>
                <h3 v-else>4. Additional Participants</h3>
              </div>
            </div>
            <div class="col-12 participant-container">
              <div v-for="(item, index) in formData.participants" v-bind:key="index">
                <div class="card panel-default">
                  <div class="card-header">
                    <h3 class="card-title">
                      Participant {{index + 1}}
                      <span class="float-right">
                        <a v-b-modal="'removeItemModal-'+index">
                          <b-icon icon="trash"></b-icon>
                        </a>
                        <b-modal :id="'removeItemModal-'+index" :title="'Delete Participant #'+(index+1)" ok-title="YES" cancel-title="NO" @ok="removeItem(formData.participants, index);" centered no-fade>
                          <p class="my-4">Are you sure you would like to delete Participant (#{{index+1}})</p>
                        </b-modal>
                      </span>
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6">
                        <ValidationProvider rules="required" v-slot="{ classes, errors }">
                          <div class="form-group">
                            <label><span class="text-danger">*</span>First Name</label>
                            <input type="text" class="form-control" :class="classes" v-model="item.firstName">
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider rules="required" v-slot="{ classes, errors }">
                          <div class="form-group">
                            <label><span class="text-danger">*</span>Last Name</label>
                            <input type="text" class="form-control" :class="classes" v-model="item.lastName">
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider rules="required" v-slot="{ classes, errors }">
                          <div class="form-group">
                            <label><span class="text-danger">*</span>Work Phone</label>
                            <input type="text" class="form-control" :class="classes" v-model="item.phone">
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider vid="email" rules="required|email" v-slot="{ classes, errors }">
                          <div class="form-group">
                            <label><span class="text-danger">*</span>Email</label>
                            <input type="email" class="form-control" :class="classes" v-model="item.email">
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6" v-if="(formData.msEventType==1 || formData.msEventType==2)">
                        <ValidationProvider rules="required" v-slot="{ classes, errors }">
                          <div class="form-group">
                            <label><span class="text-danger">*</span>Would you like to have lunch?</label>
                            <select class="form-control" :class="classes" v-model="item.isLunchNeeded">
                              <option value="">-Select-</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6" v-if="(formData.msEventType==1 || formData.msEventType==2) && item.isLunchNeeded==1">
                        <ValidationProvider rules="required" v-slot="{ classes, errors }">
                          <div class="form-group">
                            <label><span class="text-danger">*</span>Lunch</label>
                            <select class="form-control" :class="classes" v-model="item.lunchTypeId">
                              <option value="">-Select Option-</option>
                              <option v-for="lunchType in lunchTypes" v-bind:value="lunchType.id" v-bind:key="lunchType.id">
                                {{ lunchType.title }}
                              </option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-6">
                        <ValidationProvider rules="required" v-slot="{ classes, errors }" v-if="formData.sponsorshipType==2">
                          <div class="form-group">
                            <label><span class="text-danger">*</span>T-Shirt Size</label>
                            <select class="form-control" :class="classes" v-model="item.tShirtSizeId">
                              <option value="" >-Select Option-</option>
                              <option v-for="tShirtSize in tShirtSizes" v-bind:value="tShirtSize.id" v-bind:key="tShirtSize.id">
                                {{ tShirtSize.title }}
                              </option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span><button class="btn btn-blue mt-3" type="button" title="Add Participant" @click="addItem(formData.participants, newParticipant)">Add Participant</button></span>
            </div>
          </div>
          <div class="styled-divider my-3"></div>
          <div class="row mt-5" v-if="!isEdit">
            <div class="form-group col-12">
              <vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" :sitekey="recaptchaClientKey"></vue-recaptcha>
              <p class="text-danger mt-1">{{invalidField}}</p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
                <button class="btn btn-yellow" v-on:click="register" v-if="!isEdit">SUBMIT COMPANY REGISTRATION</button>
                <button class="btn btn-yellow" v-on:click="edit" v-if="isEdit">UPDATE COMPANY REGISTRATION</button>
            </div>
            <div v-if="isBusy" class="col-md-6 align-self-center">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else class="col-md-6 mt-2">
              <p v-if="!valid && showErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
              <p v-if="errorMsg"><strong><span class="text-danger">{{errorMsg}}</span></strong></p>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { companyRegistration, participant } from '../models/company-registration';
  import dataService from '../services/data.service';
  import { states } from '../data/states'
  import { tShirtSizes } from '../data/tshirt-sizes'
  import { lunchTypes } from '../data/lunch-types'
  import { VueRecaptcha } from 'vue-recaptcha'
  import { config } from '../../config'

  export default {
    props: ['regId'],
    components: { VueRecaptcha },
    computed: {
      recaptchaClientKey: function () {
        return config.RECAPTCHA_CLIENT_KEY;
      }
    },
    data: function() {
      return {
        isBusy: false,
        isEdit: false,
        showErrorMsg: false,
        states: states,
        lunchTypes: lunchTypes,
        tShirtSizes: tShirtSizes,
        formData: JSON.parse(JSON.stringify(companyRegistration)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        newParticipant: participant,
        attachment: null,
        imageSrc: null,
        attachment_eps: null,
        attachment_eps_label: null,
        errorMsg: null,
        invalidField: '',
      }
    },
    async mounted (){
      this.isBusy = true;
      try {
        if(this.$route.params.regId) {
          this.isEdit = true;
          let regData = await dataService.getRegistrationData(this.$route.params.regId);
          this.formData = regData.data.data;
          if (this.formData.companyLogo) {
            let downloadResponse = await dataService.downloadFile(this.formData.regId, 'COMPANYLOGO', this.formData.companyLogo);
            this.imageSrc = downloadResponse.data.data;
          }
          this.attachment_eps_label = this.formData.companyLogoEPS;
        }

        this.isBusy = false;

      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    methods: {
      onVerify: function (response) {
        if (response) {
          this.invalidField = '';
          this.formData.gResponse = response;
        } 
      },

      register: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        // recaptcha
        if (!this.formData.gResponse || this.formData.gResponse.length === 0) {
          // reCaptcha not verified
          this.isBusy = false;
          this.invalidField = 'Please verify using reCAPTCHA.'
          return;
        }

        this.clearHiddenFields();
        this.setRelatedFields();

        try {
          this.attachment = this.$refs.file.files[0];
          if (this.attachment) this.formData.companyLogo = this.attachment.name;
          this.attachment_eps = this.$refs.file_eps.files[0];
          if (this.attachment_eps) this.formData.companyLogoEPS = this.attachment_eps.name;

          let response = await dataService.createCompanyReg(this.formData);
          if (response.data.code == 0) {
            let dataURL;
            if(this.attachment) {
              dataURL = await dataService.readAsDataURL(this.attachment);
              await dataService.uploadFile({regId: response.data.data.regId, attachmentType: "COMPANYLOGO", fileName: this.attachment.name, dataURL: dataURL.data, type: dataURL.type});
            }
            if(this.attachment_eps) {
              dataURL = await dataService.readAsDataURL(this.attachment_eps);
              await dataService.uploadFile({regId: response.data.data.regId, attachmentType: "COMPANYLOGOEPS", fileName: this.attachment_eps.name, dataURL: dataURL.data, type: dataURL.type});
            }
            this.$router.push({ name: 'thank-you', params: {regType: this.formData.regType}});
          } else if (response.data.code == -2) {
            this.$refs.form.setErrors({email: [response.data.message]});
            this.showErrorMsg = true;
          } else {
            console.log(response.data.message);
          }
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          if(error.response && error.response.data) {
						if(error.response.data.code == -4) {
							this.invalidField = error.response.data.message;
							return;
						}
					}
          console.log(error);
          this.$router.push({ name: 'error-500' });
        }
      },

      edit: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        this.clearHiddenFields();
        this.setRelatedFields();

        try {
          try {
            let attachments = [];
            this.attachment = this.$refs.file.files[0];
            // if new attachment or is not a sponsor then delete previously upoaded file
            if (this.formData.companyLogo && (this.attachment || this.formData.isSponsor != 1)) {
              await dataService.deleteFile(this.formData.regId, 'COMPANYLOGO', this.formData.companyLogo);
              this.formData.companyLogo = "";
            }
            if (this.attachment) {
              this.formData.companyLogo = this.attachment.name;
              let dataURL = await dataService.readAsDataURL(this.attachment);
              attachments.push({regId: this.$route.params.regId, attachmentType: 'COMPANYLOGO', fileName: this.attachment.name, dataURL: dataURL.data, type: dataURL.type});
            }

            this.attachment_eps = this.$refs.file_eps.files[0];
            // if new EPS attachment or is not a 'Wingfoot' sponsor then delete previously upoaded file
            if (this.formData.companyLogoEPS && (this.attachment_eps || this.formData.isSponsor != 1 || this.formData.sponsorshipType != 2)) {
              await dataService.deleteFile(this.formData.regId, 'COMPANYLOGOEPS', this.formData.companyLogoEPS);
              this.formData.companyLogoEPS = "";
            }
            if (this.attachment_eps) {
              this.formData.companyLogoEPS = this.attachment_eps.name;
              let dataURL = await dataService.readAsDataURL(this.attachment_eps);
              attachments.push({regId: this.$route.params.regId, attachmentType: 'COMPANYLOGOEPS', fileName: this.attachment_eps.name, dataURL: dataURL.data, type: dataURL.type});
            }

            if (attachments.length) {
              await dataService.uploadFiles(attachments);
            }
          } catch (err) {
            this.isBusy = false;
            this.errorMsg = "Logo hasn't been updated. Please try again!"
            //throw err;
            console.log(err);
            return;
          }

          await dataService.updateCompanyReg(this.$route.params.regId, this.formData);
          this.isBusy = false;
          this.$router.push({ name: 'thank-you', params: {regType: this.formData.regType, regId: this.$route.params.regId}});          
        } catch (error) {
          this.isBusy = false;
          console.log(error);
          this.$router.push({ name: 'error-500' });
        }
      },

      removeItem: function (array, index) {
        array.splice(index, 1);
      },

      addItem: function (array, item) {
        let newItem = JSON.parse(JSON.stringify(item)); // clone object to leave template object intact
        array.push(newItem);
      },

      clearHiddenFields: function () {
        if (this.formData.eventChoice == '0') {
          this.formData.hsNumberOfTables = "";
          this.formData.hsIsElectricityNeeded = "";
        }

        if (this.formData.eventChoice == 1 || this.formData.msEventType == 1) {
          this.formData.msInformationFair.numberOfTables = "";
          this.formData.msInformationFair.isElectricityNeeded = "";
        }

        if (this.formData.eventChoice == 1 || this.formData.msEventType == '0'){
          this.formData.msInteractive.description = "";
          this.formData.msInteractive.classroomStyle = "";
          this.formData.msInteractive.isAdditionalTablesNeeded = "";
          this.formData.msInteractive.numberOfTables = "";
          this.formData.msInteractive.isProjectorNeeded = "";
          this.formData.msInteractive.isInternetNeeded = "";
          this.formData.msInteractive.isExtensionCordNeeded = "";
          this.formData.msInteractive.cables = "";
          this.formData.msInteractive.isLunchNeeded = "";
          this.formData.msInteractive.lunchTypeId = "";
        }

        if (this.formData.eventChoice == 1 || this.formData.eventChoice == 3) { //if user selects either HS or Sponsorship Only
          this.formData.msEventType = '';
          this.formData.msInteractive.description = "";
          this.formData.msInteractive.classroomStyle = "";
          this.formData.msInteractive.isAdditionalTablesNeeded = "";
          this.formData.msInteractive.numberOfTables = "";
          this.formData.msInteractive.isProjectorNeeded = "";
          this.formData.msInteractive.isInternetNeeded = "";
          this.formData.msInteractive.isExtensionCordNeeded = "";
          this.formData.msInteractive.cables = "";
          this.formData.msInteractive.isLunchNeeded = "";
          this.formData.msInteractive.lunchTypeId = "";
        }

        if (this.formData.isSponsor != 1) {
          this.formData.sponsorshipType = "";
          this.formData.tShirtSizeId = "";
        }
      },

      setRelatedFields: function () {
        this.states.forEach( item => {
          if (item.id == this.formData.companyStateId) this.formData.companyStateName = item.title;
        });

        this.lunchTypes.forEach( item => {
          if (item.id == this.formData.msInteractive.lunchTypeId) this.formData.msInteractive.lunchTypeName = item.title;
        });

        this.tShirtSizes.forEach( item => {
          if (item.id == this.formData.tShirtSizeId) this.formData.tShirtSizeName = item.title;
        });

        this.formData.participants.forEach( participant => {
          this.lunchTypes.forEach( item => {
            if (item.id == participant.lunchTypeId) participant.lunchTypeName = item.title;
          });
          this.tShirtSizes.forEach( item => {
            if (item.id == participant.tShirtSizeId) participant.tShirtSizeName = item.title;
          });
        });
      },
      

    }
  }

</script>