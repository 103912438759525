<template>
  <div class="gr-border container">
    <div class="row">
      <div class="text-center gr-bak col-12">
        <h1 class="dk-blue upper"><strong>{{title}}</strong></h1>
      </div>
    </div>
    <div class="row d-none d-lg-flex mt-3">
      <div class="col-6 text-center">
        <span class="float-right"><img src="../assets/img/icon-yellow-1.png"><br>Verify Birthdate</span>
      </div>
      <div class="col-6 text-center">
        <span class="float-left"><img src="../assets/img/icon-gray-2.png"><br>Registration</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form @submit="verify">
          <div class="form-heading dark-bak">
            <h3>Verify Your Birthdate</h3>
          </div>
          <div>
            <p>Persons under the age of 13 require an adult to complete the registration process.<br/> Please enter the birthdate of the person completing this form.</p>
            <div class="row my-3 align-items-end">
              <div class="col-md-4">
                <label for="birthdate"><span class="danger">*</span>Birthdate (mm/yyyy)</label>
                <input type="text" id="birthdate" name="birthdate" class="form-control bday" v-model="birthDate" placeholder="mm/yyyy">
              </div>
              <div class="col-md-4">
                <button type="submit" class="btn btn-yellow btn-verify-age">VERIFY</button>
              </div>
              <div class="col-12" v-show="errorMsg"><p class="text-danger">{{errorMsg}}</p></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data: function() {
      return {
        title: "",
        birthDate: "",
        errorMsg: ""
      };
    },
    beforeMount() {
      switch(this.$route.params.regType){
        case 'MSIND':
          this.title = `Middle School STEM Career Day ${this.$stemYear} Registration`;
          break;
        case 'MSGROUP':
          this.title = `Middle School STEM Career Day ${this.$stemYear} Group Registration`;
          break;
        case 'HSIND':
          this.title = `High School STEM Career Day ${this.$stemYear} Registration`;
          break;
      }
    },
    methods: {
      verify: function(event){
        event.preventDefault();
        let monthYear = this.birthDate.split('/'); 
        let date = new Date(monthYear[1], monthYear[0]);
        let age = (new Date() - date)/(1000*60*60*24*365);
        if(age > 13) {
          switch(this.$route.params.regType){
            case 'MSIND':
              this.$router.push({ name: 'ms-ind-reg-new', params: { ageVerified: true } });
              break;
            case 'MSGROUP':
              this.$router.push({ name: 'ms-group-reg-new', params: { ageVerified: true } });
              break;
            case 'HSIND':
              this.$router.push({ name: 'hs-ind-reg-new', params: { ageVerified: true } });
              break;
            default:
              this.$router.push({ name: 'register' });
          }
        }
        else
          this.errorMsg = "Your age must be 13 or older to continue"
      }
    }
  }

</script>