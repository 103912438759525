const currentDomain = window.location.origin;

// array of config options per domain(s)
const gyConfigOptions = [
    {
        domain: process.env.VUE_APP_GYPASS_DOMAIN_PROD,
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN_PROD,
        authClientId: process.env.VUE_APP_AUTH0_CLIENT_ID_PROD,
        api: process.env.VUE_APP_API_PROD,
    },
    {
        domain: process.env.VUE_APP_GYPASS_DOMAIN_DEV,
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN_TEST,
        authClientId: process.env.VUE_APP_AUTH0_CLIENT_ID_TEST,
        api: process.env.VUE_APP_API_DEV,
    },
    {
        domain: process.env.VUE_APP_GYPASS_DOMAIN_LOCAL,
        authDomain: process.env.VUE_APP_AUTH0_DOMAIN_TEST,
        authClientId: process.env.VUE_APP_AUTH0_CLIENT_ID_TEST,
        api: process.env.VUE_APP_API_DEV,
    }
]

let gyAuthConfig = gyConfigOptions[1]; // default

// loop to see which domain matches current domain 
for (let gyConfigOption of gyConfigOptions) {
    if (gyConfigOption.domain.indexOf(currentDomain.toLowerCase()) !== -1) {
        // update config
        gyAuthConfig = gyConfigOption;
    } 
    
}
console.log('gyAuthConfig', gyAuthConfig);
export const domain = gyAuthConfig.authDomain;
export const clientId = gyAuthConfig.authClientId;

//Set the path for apis calls / token audience
export const apiPath = gyAuthConfig.api;