import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuthInstance } from '@/auth'
import Home from './views/Home.vue'
import Register from './views/Register.vue'
import MSIndReg from './views/MSIndReg.vue'
import MSGroupReg from './views/MSGroupReg.vue'
import HSIndReg from './views/HSIndReg.vue'
import HSChaperoneReg from './views/HSChaperoneReg.vue'
import CompanyReg from './views/CompanyReg.vue'
import Scholarships from './views/Scholarships.vue'
import ScholarshipDetails from './views/ScholarshipDetails.vue'
import ScholarshipApplication from './views/ScholarshipApplication.vue'
import MultipleRegistration from './views/MultipleRegistration.vue'
import VerifyAge from './views/VerifyAge.vue'
import ThankYou from './views/ThankYou.vue'
import Contact from './views/Contact.vue'
import ContactThankYou from './views/ContactThankYou.vue'
import Grants from './views/Grants.vue'
import About from './views/About.vue'
import ExploringPost from './views/ExploringPost'
import Map from './views/Map.vue'
import ConsentFormHS from './views/ConsentFormHS.vue'
import ConsentFormMS from './views/ConsentFormMS.vue'
import InternalServerError from './views/500.vue'
import NotFound from './views/404.vue'
import AccessDenied from './views/AccessDenied.vue'
import AccountAlreadyExists from './views/AccountAlreadyExists'
import SignUp from './views/SignUp.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/register', name: 'register', component: Register },
  { path: '/ms-ind-reg-new', name: 'ms-ind-reg-new',  component: MSIndReg, props: true,
    beforeEnter(to, from, next) {
      if(Vue.prototype.$isMSIndRegActive) next()
      else next({name: 'home'})
    }
  },
  { path: '/ms-ind-reg-edit', name: 'ms-ind-reg-edit',  component: MSIndReg, props: true },
  { path: '/ms-group-reg-new', name: 'ms-group-reg-new',  component: MSGroupReg, props: true,
    beforeEnter(to, from, next) {
      if(Vue.prototype.$isMSGroupRegActive) next()
      else next({name: 'home'})
    }
  },
  { path: '/ms-group-reg-edit', name: 'ms-group-reg-edit',  component: MSGroupReg, props: true },
  { path: '/hs-ind-reg-new', name: 'hs-ind-reg-new',  component: HSIndReg, props: true,
    beforeEnter(to, from, next) {
      if(Vue.prototype.$isHSIndRegActive) next()
      else next({name: 'home'})
    }
  },
  { path: '/hs-ind-reg-edit', name: 'hs-ind-reg-edit',  component: HSIndReg, props: true },
  { path: '/hs-chaperone-reg-new', name: 'hs-chaperone-reg-new',  component: HSChaperoneReg, props: true,
    beforeEnter(to, from, next) {
      if(Vue.prototype.$isHSChaperoneRegActive) next()
      else next({name: 'home'})
    }
  },
  { path: '/hs-chaperone-reg-edit', name: 'hs-chaperone-reg-edit',  component: HSChaperoneReg, props: true },
  { path: '/company-reg-new', name: 'company-reg-new',  component: CompanyReg, props: true,
    beforeEnter(to, from, next) {
      if(Vue.prototype.$isCompanyRegActive) next()
      else next({name: 'home'})
    }
  },
  { path: '/company-reg-edit', name: 'company-reg-edit',  component: CompanyReg, props: true },
  { path: '/scholarships', name: 'scholarships', component: Scholarships },
  { path: '/scholarship-details', name: 'scholarship-details', component: ScholarshipDetails,
    beforeEnter(to, from, next) {
      if(Vue.prototype.$isScholarshipRegActive) next()
      else next({name: 'home'})
    }
  },
  { path: '/scholarship-application-new', name: 'scholarship-application-new', component: ScholarshipApplication,
    beforeEnter(to, from, next) {
      if(Vue.prototype.$isScholarshipRegActive) next()
      else next({name: 'home'})
    }
  },
  { path: '/scholarship-application-edit', name: 'scholarship-application-edit', component: ScholarshipApplication },
  { path: '/signin', name: 'signin' },
  { path: '/signup', name: 'signup', component: SignUp },
  { path: '/multiple', name: 'multiple', component: MultipleRegistration, props: true },
  { path: '/verify-age', name: 'verify-age',  component: VerifyAge, props: true,
    beforeEnter(to, from, next) {
      if(to.params.regType == 'MSIND' && Vue.prototype.$isMSIndRegActive
        || to.params.regType == 'MSGROUP' && Vue.prototype.$isMSGroupRegActive
        || to.params.regType == 'HSIND' && Vue.prototype.$isHSIndRegActive)
        next()
      else next({name: 'home'})
    }
  },
  { path: '/thank-you', name: 'thank-you',  component: ThankYou, props: true },
  { path: '/contact', name: 'contact',  component: Contact },
  { path: '/contact-thank-you', name: 'contact-thank-you',  component: ContactThankYou },
  { path: '/grants', name: 'grants',  component: Grants },
  { path: '/about', name: 'about',  component: About },
  { path: '/exploring-post', name: 'exploring-post',  component: ExploringPost },
  { path: '/map', name: 'map',  component: Map },
  { path: '/consent-form-hs', name: 'consent-form-hs',  component: ConsentFormHS },
  { path: '/consent-form-ms', name: 'consent-form-ms',  component: ConsentFormMS },
  { path: '/error-500', name: 'error-500',  component: InternalServerError },
  { path: '/error-404', name: 'error-404',  component: NotFound },
  { path: '/access-denied', name: 'access-denied',  component: AccessDenied },
  { path: '/account-already-exists', name: 'account-already-exists',  component: AccountAlreadyExists },
  { path: '*', redirect: '/error-404' }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})

const freeAccessRoutes = ['home', 'register', 'verify-age', 'ms-ind-reg-new', 'ms-group-reg-new', 'hs-ind-reg-new',
  'hs-chaperone-reg-new', 'company-reg-new', 'scholarships', 'scholarship-details', 'scholarship-application-new',
  'thank-you', 'contact', 'contact-thank-you', 'grants', 'about', 'map', 'consent-form-hs', 'consent-form-ms', 
  'exploring-post', 'error-500', 'error-404', 'access-denied', 'account-already-exists']

router.beforeEach(async (to, _from, next) => {
  const authInstance = getAuthInstance();
  if (!authInstance.auth0Client) {
    await authInstance.initialize();
  }

  if (freeAccessRoutes.includes(to.name)) {
    next();
    return;
  }

  // Authentication
  if (authInstance.isAuthenticated || to.name === 'signup') {
      next();
  } else {
    await authInstance.loginWithRedirect();
  } 
});

export default router