export default {
  namespaced: true,
  state: {
    user: {
      auth: false,
      email: '',
      name: ''
    }
  },
  getters: {
    auth: state => {
      return state.user.auth
    }
  },
  mutations: {
    changeAuth(state, payload) {
      state.user.auth = payload.isAuthenticated;
    },
    setUserEmail(state, payload) {
      state.user.email = payload;
    },
    setUserName(state, payload) {
      state.user.name = payload;
    }
  }
}