import axios from 'axios';
import router from '../router';
import { getAuthInstance } from '@/auth'

const getTokenAndCallApi = async (config) => {
  const authInstance = getAuthInstance();
  let accessToken = await authInstance.getTokenSilently();
  // azure swa replaces 'authorization' header with its own so we use custom one
  config.headers = {
    'x-custom-authorization':  `Bearer ${accessToken}`
  };
  try {
    return await axios(config);
  } catch (err) {
    switch (err.response.status) {
      case 401:
      case 403:
        router.push({ name: 'access-denied'});
        break;
    }
    //return data to avoid errors in console
    return {data: {data: {length: 0}}};
  }
};

export default {

  // GET requests
  
  /**
   * Get list of Catalog items by Category
   */
  async getCatalogItemsByCategory(category) {
    return axios.get(`/api/catalog-items/${category}`);
  },

  /**
   * Get list of Middle School Groups
   */
  async getMSGroups() {
    return axios.get('/api/middle-school-groups');
  },

  /**
   * Get Registration data
   * 
   * @param regId Registration id
   */
  async getRegistrationData(regId) {
    return getTokenAndCallApi({url: `/api/registrations/${regId}`, method: 'GET'});
  },

  /**
   * Get User Registrations
   */
  async getRegistrations() {
    return getTokenAndCallApi({url: `/api/user-registrations`, method: 'GET'});
  },

  /**
   * Get MS Group Members
   */
  async getMSGroupMembers(regId) {
    return getTokenAndCallApi({url: `/api/ms-group-reg/${regId}/members`, method: 'GET'});
  },

  // POST requests

  /**
   * Register middle school student
   * 
   * @param data Student data object
   */
  async createMSIndReg(data) {
    return axios.post(`/api/ms-ind-reg`, JSON.stringify(data));
  },

  /**
   * Register middle school group
   * 
   * @param data Group data object
   */
  async createMSGroupReg(data) {
    return axios.post(`/api/ms-group-reg`, JSON.stringify(data));
  },

  /**
   * Register high school student
   * 
   * @param data Student data object
   */
  async createHSIndReg(data) {
    return axios.post(`/api/hs-ind-reg`, JSON.stringify(data));
  },

  /**
   * Send day-of high school consent form
   * 
   * @param data Consent form data object
   */
    async sendDayOfHSConsent(data) {
      return axios.post(`/api/hs-day-of-consent`, JSON.stringify(data));
    },

  /**
   * Send day-of high school consent form
   * 
   * @param data Consent form data object
   */
      async sendDayOfMSConsent(data) {
        return axios.post(`/api/ms-day-of-consent`, JSON.stringify(data));
      },

  /**
   * Register high school chaperone
   * 
   * @param data Chaperone data object
   */
  async createHSChaperoneReg(data) {
    return axios.post(`/api/hs-chaperone-reg`, JSON.stringify(data));
  },

  async verifyGroupCode(groupId, groupCode) {
    return axios.post('/api/verify-group-code', {groupId: groupId, groupCode: groupCode});
  },

  /**
   * Register company
   * 
   * @param data Company data object
   */
  async createCompanyReg(data) {
    return axios.post(`/api/company-reg`, JSON.stringify(data));
  },

  /**
   * Create Scholarship Application
   * 
   * @param data Scholarship Application object
   */
  async createScholarshipApplication(data) {
    return axios.post(`/api/scholarships`, data);
  },

  async sendQuestion(data) {
    return axios.post('/api/send-question', data);
  },
 
  // PUT requests

  /**
   * Update middle school student registration
   * 
   * @param regId Registration id
   * @param data Student data object
   */
  async updateMSIndReg(regId, data) {
    return getTokenAndCallApi({url: `/api/ms-ind-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update middle school group registration
   * 
   * @param regId Registration id
   * @param data Group data object
   */
  async updateMSGroupReg(regId, data) {
    return getTokenAndCallApi({url: `/api/ms-group-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update high school student registration
   * 
   * @param regId Registration id
   * @param data Student data object
   */
  async updateHSIndReg(regId, data) {
    return getTokenAndCallApi({url: `/api/hs-ind-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update high school chaperone registration
   * 
   * @param regId Registration id
   * @param data Chaperone data object
   */
  async updateHSChaperoneReg(regId, data) {
    return getTokenAndCallApi({url: `/api/hs-chaperone-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update company registration
   * 
   * @param regId Registration id
   * @param data Comapany data object
   */
  async updateCompanyReg(regId, data) {
    return getTokenAndCallApi({url: `/api/company-reg/${regId}`, method: 'PUT', data: JSON.stringify(data)});
  },

  /**
   * Update Scholarship Application
   * 
   * @param regId Registration id
   * @param data Scholarship Application object
   */
  async updateScholarshipApplication(regId, data) {
    return getTokenAndCallApi({url: `/api/scholarships/${regId}`, method: 'PUT', data: data});
  },

  /**
   * Update Registration Status
   * 
   * @param data MS Group member status data
   */
  async updateRegistrationStatus(data) {
    return getTokenAndCallApi({url: `/api/registration-status`, method: 'PUT', data: data});
  },

  // Attachments

  async uploadFile(fileData) {
    return axios.post("/api/files", [fileData]);
  },
 
  async uploadFiles(attachments) {
    return axios.post("/api/files", attachments);
  },

  async downloadFile(regId, attachmentType, fileName) {
    return getTokenAndCallApi({url: `/api/files/${regId}/${attachmentType}/${fileName}`, method: 'GET'});
  },

  async deleteFile(regId, attachmentType, fileName) {
    return getTokenAndCallApi({url: `/api/files/${regId}/${attachmentType}/${fileName}`, method: 'DELETE'});
  },

  async readAsDataURL(file) {
    return new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = function() {
        return resolve({data: fileReader.result, name: file.name, size: file.size, type: file.type});
      }
      fileReader.readAsDataURL(file);
    })
  }
/*
  function blobToDataURL(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = _e => resolve(reader.result);
      reader.onerror = _e => reject(reader.error);
      reader.onabort = _e => reject(new Error("Read aborted"));
      reader.readAsDataURL(blob);
    });
  }
*/
}