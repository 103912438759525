<template>
  <div class="gr-border container">
    <div class="row">
      <div class="col-12">

        <div v-if="regType=='COMPANY'" class="row">
          <div v-if="isEdit" class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Registration has been updated!</strong></h2>
            <p>Thank you for updating your registration for the {{$stemYear}} STEM Career Day.</p>
            <br/>
          </div>
          <div v-else class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Registration is complete!</strong></h2>
            <p>Thank you for registering your company for STEM Career Day {{$stemYear}}. More details will be sent out at a later date. We look forward to seeing you at STEM Career Day {{$stemYear}}.</p>
            <!-- <p>If you checked that you are interested in participating in High School STEM Career Day {{$stemYear}}, please lookout for further details that we will send out to you soon.</p> -->
            <br/>
          </div>
          <br/>
        </div>

        <div v-else-if="regType=='HSIND'" class="row">
          <div v-if="isEdit" class="center-txt col-12">
            <div v-if="isContestFormSubmitted">
              <br/>
              <h2 class="upper"><strong><span class="text-success"></span> Registration has been updated!</strong></h2>
              <p>Thank you for updating your registration for the High School STEM Career Day {{$stemYear}}. We look forward to seeing you soon!</p>
              <p>Want more information from the University of Akron? <a href="https://www.uakron.edu" target="_blank">Request information here.</a></p>
              <br/>
            </div>
            <!-- <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration has been updated, but still in progress!</strong></h2>
              <p>Thank you for beginning your registration for High School STEM Career Day {{$stemYear}}. However, your registration is not complete until your Parent/Legal Guardian completes your consent form. Please check your email for more information on how to complete the e-consent form. We look forward to seeing you soon!</p>
              <p>Want more information from the University of Akron? <a href="https://www.uakron.edu" target="_blank">Request information here.</a></p>
              <br/>
            </div> -->
            <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration has been updated, but still in progress!</strong></h2>
              <p>Thank you for beginning your registration for High School STEM Career Day {{$stemYear}}. However, your registration is not finalized until your parent/legal guardian completes your consent form. Please complete the paper consent form provided by your school and return to your school as soon as possible. You may also log back in and complete the form online. Check your email for instructions on how to log in to edit your registration.</p>
              <p>Want more information from the University of Akron? <a href="https://www.uakron.edu" target="_blank">Request information here.</a></p>
              <br/>
            </div>
          </div>
          <div v-else class="center-txt col-12">
            <div v-if="isContestFormSubmitted">
              <br/>
              <h2 class="upper"><strong><span class="text-success"></span> Registration is complete!</strong></h2>
              <p>Thank you for submitting your registration for High School STEM Career Day {{$stemYear}}. Your registration is complete. An email will be sent to you regarding High School STEM Career Day {{$stemYear}}. We look forward to seeing you soon!</p>
              <p>Want more information from the University of Akron? <a href="https://www.uakron.edu" target="_blank">Request information here.</a></p>
              <br/>
            </div>
            <!-- <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration in progress</strong></h2>
              <p>Thank you for beginning your registration for High School STEM Career Day {{$stemYear}}. However, your registration is not complete until your Parent/Legal Guardian completes your consent form. Please check your email for more information on how to complete the e-consent form. We look forward to seeing you soon!</p>
              <p>Want more information from the University of Akron? <a href="https://www.uakron.edu" target="_blank">Request information here.</a></p>
              <br/>
            </div> -->
            <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration in progress</strong></h2>
              <p>Thank you for beginning your registration for High School STEM Career Day {{$stemYear}}. However, your registration is not finalized until your parent/legal guardian completes your consent form. Please complete the paper consent form provided by your school and return to your school as soon as possible. You may also log back in and complete the form online. Check your email for instructions on how to log in to edit your registration.</p>
              <p>Want more information from the University of Akron? <a href="https://www.uakron.edu" target="_blank">Request information here.</a></p>
              <br/>
            </div>
          </div>
        </div>

        <div v-else-if="regType=='HSCHAPERONE'" class="row">
          <div v-if="isEdit" class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Registration has been updated!</strong></h2>
            <p>Thank you for updating your registration as a chaperone for High School STEM Career Day {{$stemYear}}. We look forward to seeing you soon!</p>
            <br/>
          </div>
          <div v-else class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Registration Success!</strong></h2>
            <p>Thank you for submitting your registration as a chaperone for High School STEM Career Day {{$stemYear}}. Your registration is complete. An email will be sent to you regarding High School STEM Career Day {{$stemYear}}.</p>
            <br/>
          </div>
        </div>

        <div v-else-if="regType=='MSIND'" class="row">
          <div v-if="isEdit" class="center-txt col-12">
            <div v-if="isContestFormSubmitted">
              <br/>
              <h2 class="upper"><strong><span class="text-success"></span> Registration has been updated!</strong></h2>
              <p>Thank you for updating your registration for STEM Career Day {{$stemYear}}. We look forward to seeing you soon!</p>
              <br/>
            </div>
            <!-- <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration has been updated, but still in progress!</strong></h2>
              <p>Thank you for updating your registration for Middle School STEM Career Day {{$stemYear}}. However, your registration is not complete until your Parent/Legal Guardian completes your consent form. Please check your email for more information on how to complete the e-consent form. We look forward to seeing you soon!</p>
              <br/>
            </div> -->
            <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration has been updated, but still in progress!</strong></h2>
              <p>Thank you for updating your registration for Middle School STEM Career Day {{$stemYear}}. However, your registration is not finalized until your parent/legal guardian completes your consent form. Please complete the paper consent form provided by your school and return to your school as soon as possible. You may also log back in and complete the form online. Check your email for instructions on how to log in to edit your registration.</p>
              <br/>
            </div>
          </div>
          <div v-else class="center-txt col-12">
            <div v-if="isContestFormSubmitted">
              <br/>
              <h2 class="upper"><strong><span class="text-success"></span> Registration is complete!</strong></h2>
              <p>Thank you for submitting your registration for STEM Career Day {{$stemYear}}. Your registration is complete. An email will be sent to you regarding STEM Career Day {{$stemYear}}.</p>
              <br/>
            </div>
            <!-- <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration in progress</strong></h2>
              <p>Thank you for beginning your registration for Middle School STEM Career Day {{$stemYear}}. However, your registration is not complete until your Parent/Legal Guardian completes your consent form. Please check your email for more information on how to complete the e-consent form. We look forward to seeing you soon!</p>
              <br/>
            </div> -->
            <div v-else>
              <br/>
              <h2 class="upper"><strong><span class="text-warning"></span> Registration in progress</strong></h2>
              <p>Thank you for beginning your registration for Middle School STEM Career Day {{$stemYear}}. However, your registration is not finalized until your parent/legal guardian completes your consent form. Please complete the paper consent form provided by your school and return to your school as soon as possible. You may also log back in and complete the form online. Check your email for instructions on how to log in to edit your registration.</p>
              <br/>
            </div>
          </div>
        </div>

        <div v-else-if="regType=='MSGROUP'" class="row">
          <div v-if="isEdit" class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Registration has been updated!</strong></h2>
            <p>Thank you for updating your registration for STEM Career Day {{$stemYear}}. We look forward to seeing you soon!</p>
            <br/>
          </div>
          <div v-else class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Group {{groupName}} has been created successfully!</strong></h2>
            <p>Thank you for registering your group for STEM Career Day {{$stemYear}}. Students from your school will now be able to select your group when registering for the event.</p>
            <p>Next steps:</p>
            <ol>
              <li>Communicate your group name and group code to all students who are planning to be a part of your group. This information will be in your confirmation email for reference.</li>
              <li>Ask that the students fill out the registration form and have a Parent or Legal Guardian present.</li>
              <li>Log in to retrieve the list of group members and their registration status.</li>
            </ol>
            <p>Note: Students names will not appear in your group until they have successfully entered the group name and group code.</p>
            <p>Thank you for registering. Please contact us if you have any questions.</p>
            <br/>
          </div>
        </div>

        <div v-else-if="regType=='SCHOLARSHIP'" class="row">
          <div v-if="isEdit" class="center-txt col-12">
            <h2 class="upper"><strong><span class="text-success"></span> Scholarship Application has been updated!</strong></h2>
          </div>
          <div v-else class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Thank you for your submission!</strong></h2>
            <p>A confirmation email has been sent to your email address.</p>
            <br/>
          </div>
        </div>

        <div v-else-if="regType=='HSDAYOFCONSENT'" class="row">
          <div class="center-txt col-12">
            <br/>
            <h2 class="upper"><strong><span class="text-success"></span> Thank you for your submission!</strong></h2>
            <p>Your consent form has been submitted and received by the STEM Career Day Team.</p>
            <br/>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        regType: this.$route.params.regType,
        isEdit: this.$route.params.regId,
        isContestFormSubmitted: this.$route.params.isContestFormSubmitted,
        groupName: this.$route.params.groupName,
      };
    },
    mounted() {
      if(!this.regType) this.$router.push({ name: 'home'});
    }
  }

</script>