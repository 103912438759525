<template>
  <div class="gr-border container">
    <div class="row">
      <div class="col-12">
        <div class="text-center gr-bak mb-3">
          <h1 class="dk-blue upper"><strong>Edit Registration</strong></h1>
        </div>
        <div class="card panel-default panel-login">
          <div class="card-header">Login</div>
          <div class="card-body">
            <div class="mb-3">There are multiple registrations available for edit using this email address. Which would you like to edit?</div>
            <form @submit="editReg">
              <div class="form-group">
                <label for="regItem"><span class="text-danger">*</span>Registration to Edit</label>
                <select name="regItem" id="regItem" class="form-control" v-model="selectedReg" :disabled="isBusy">
                  <option value="">-Select Registration-</option>
                  <option v-for="reg in regList" v-bind:value="reg" v-bind:key="reg.regId">
                    {{ reg.firstName }} {{ reg.lastName }} ({{reg.regType}})
                  </option>
                </select>
                <div class="text-center mt-2" v-if="isBusy">
                  <b-spinner label="Loading..."></b-spinner>
                </div>
              </div>
              <button type="submit" class="btn btn-yellow form-control">Edit</button>
              <div id="logon_msg" v-show="errorMsg"><strong>{{errorMsg}}</strong></div>
            </form>
          </div>
        </div>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>
  import dataService from '../services/data.service';

  export default {
    data () {
      return {
        regList: [],
        selectedReg: "",
        errorMsg: "",
        isBusy: false
      };
    },
    async mounted () {
      this.isBusy = true;
      try {
        let response = await dataService.getRegistrations();
        if (response.data.data.registrations) {
          this.regList = response.data.data.registrations;
        }
      } catch (error) {
        this.errorMsg = "Sorry! Something went wrong. Please try again later."
      } finally {
        this.isBusy = false;
      }
    },
    methods: {
      editReg: function(event){
        event.preventDefault();
        
        if(this.selectedReg){
          let params = {
            regId: this.selectedReg.regId
          };
          switch(this.selectedReg.regType){
            case 'MSIND':
              this.$router.push({ name: 'ms-ind-reg-edit', params });
              break;
            case 'MSGROUP':
              this.$router.push({ name: 'ms-group-reg-edit', params: params });
              break;
            case 'HSIND':
              this.$router.push({ name: 'hs-ind-reg-edit', params: params });
              break;
            case 'HSCHAPERONE':
              this.$router.push({ name: 'hs-chaperone-reg-edit', params: params });
              break;
            case 'COMPANY':
              this.$router.push({ name: 'company-reg-edit', params: params });
              break;
            case 'SCHOLARSHIP':
              this.$router.push({ name: 'scholarship-application-edit', params: params });
              break;
            default:
              this.errorMsg = 'Unknown registration type'
          }
        } else {
          this.errorMsg = 'Please, select registration to edit'
        }
      }
    }
  }

  
</script>