<template>
	<div class="grants gr-border container">
		<div class="row">
			<div class="col-12 text-center">
				<h1>{{$stemYear}} Rube Goldberg Challenge<sup>&reg;</sup></h1>
				<br>
			</div>
			<div class="intro col-12 col-md-10 offset-md-1">
				<img class="wrapped-left" src="../assets/img/grants/rube_goldberg.png" alt="Rube Goldberg™ illustration">
				<div class="description">
					<p>Do you like to create? Design? Learn? Have fun?</p>
					<p>The challenge is on!</p>
					<p>Your goal is to create an unlikely and absurdly designed machine intended to perform a simple function in the most complicated and indirect way.</p>
					<p>This is your opportunity to showcase your creativity & win prizes for creating an individualized Rube Goldberg Machine<sup>&reg;</sup>.</p>
					<!--<<a href="files/grants/2025-rube-goldberg-prizes.xlsx" target="_blank">
						<b-icon-download></b-icon-download>
						<p>2025 Prize List</p>
					</a>>-->
				</div>
			</div>
			<div class="secondary text-banner gr-bak text-center col-md-12">
				<h2><strong>Challenge: crush an aluminum can</strong></h2>
			</div>
			<div class="comp-block col-12 col-md-6">
				<p><strong>High School Challenge</strong></p>
				<!-- <p>Letters of Intent for Participation Extended to January 31, 2024</p>
				<p>Submissions due February 7, {{$stemYear}}</p> -->
				<p>Letter of Intent due by December 31st, 2024</p>
				<p>Applications due January 29th, {{ $stemYear }}</p>
				<!-- <p>Open to Ohio Students</p> -->
				<br>
				<div class="row">
					<div class="text-center col-12">
						<a href="files/grants/hs-stem-letter-of-intent-2025.docx" target="_blank">
							<b-icon-download></b-icon-download>
							<p>HS Letter of Intent</p>
						</a>
						<a href="files/grants/hs-gy-stem-rube-goldberg-comp-rules-2025.docx" target="_blank">
							<b-icon-download></b-icon-download>
							<p>HS Rules</p>
						</a>
					</div>
					<div class="text-center col-12">
						<a href="files/grants/hs-stem-rg-application-2025.docx" target="_blank">
							<b-icon-download></b-icon-download>
							<p>HS Application</p>
						</a>
					</div>
					<!-- <div class="text-center col-4">
						<a href="files/grants/hs-stem-letter-of-intent-2024.docx" target="_blank">
							<b-icon-download></b-icon-download>
							<p>HS Letter of Intent</p>
						</a>
					</div> -->
				</div>
			</div>
			<div class="comp-block col-12 col-md-6">
				<p><strong>Middle School Challenge</strong></p>
				<!-- <p>Letters of Intent for Participation Due March 15, {{ $stemYear }}</p>
				<p>Submissions due April 15th, {{$stemYear}}</p> Rules and Application Coming Soon
				<p>Open to Ohio Students</p> -->
				<p>Applications due March 21st, {{ $stemYear }}</p>
				<br>
				<div class="row">
					<div class="text-center col-12">
						<a href="files/grants/ms-gy-stem-rube-goldberg-comp-rules-2025.docx" target="_blank">
							<b-icon-download></b-icon-download>
							<p>MS Rules</p>
						</a>
					</div>
					<div class="text-center col-12">
						<a href="files/grants/ms-stem-rg-application-2025.docx" target="_blank">
							<b-icon-download></b-icon-download>
							<p>MS Application</p>
						</a>
					</div>
					<!-- <div class="text-center col-4">
						<a href="files/grants/ms-stem-letter-of-intent-2024.docx" target="_blank">
							<b-icon-download></b-icon-download>
							<p>MS Letter of Intent</p>
						</a>
					</div> -->
				</div>
			</div>
			<div class="divide secondary text-banner col-12"></div>
			<div class="inspo col-12">
				<div class="row">
					<div class="image col-5 col-md-6">
						<img src="../assets/img/icons/need_inspiration.svg" alt="Need inspritation?"/>
					</div>
					<div class="desc col-7 col-md-6">
						<a href="files/grants/gy_stem_rube_goldberg_suggested_materials.pdf" target="_blank">
							<p><b-icon-download class="mr-2"></b-icon-download>Suggested materials</p>
						</a>
						<p><strong>Design Ideas and Space Saving Machines</strong>
							<ul>
								<li>Make a miniature Rube Goldberg Machine<sup>&reg;</sup> - your machine doesn't need to be big to win!
									<ul>
										<li>Use straws ipo paper towel tubes, marbles ipo golf balls, Lego ipo blocks</li>
									</ul>
								</li>
								<li>Make a modular Rube Goldberg Machine<sup>&reg;</sup>
									<ul>
										<li>Split your machine into modules so you can work on one section at a time</li>
									</ul>
								</li>
							</ul>
						</p>
					</div>
				</div>
			</div>

			<div class="grants-slides col-12 text-center">
				<p><strong>Click on an image below for more space saving machines and design options</strong></p>
				<a href="#" @click="slideShow($event, 0)">
					<img src="img/grants/slideshow/nihf_stem_middle_school_2019.png" alt="NIHF STEM Middle school 2019"/>
				</a>
				<a href="#" @click="slideShow($event, 1)">
					<img src="img/grants/slideshow/nihf_stem_middle_school_2017.png" alt="NIHF STEM Middle school 2017"/>
				</a>
				<a href="#" @click="slideShow($event, 2)">
					<img src="img/grants/slideshow/black_river_high_school.png" alt="Black River High School"/>
				</a>
				<a href="#" @click="slideShow($event, 3)">
					<img src="img/grants/slideshow/brecksville_broadview_hts_2015.png" alt="Brecksville-Broadview Heights 2015"/>
				</a>
				<a href="#" @click="slideShow($event, 4)">
					<img src="img/grants/slideshow/liberty_high_school_2019.png" alt="Liberty High School 2019"/>
				</a>
			</div>
			<LightBox
				ref="lightbox"
				:media="media"
				:show-caption="true"
				:show-light-box="false"
			/>
		</div>
	</div>
</template>

<script>
	import LightBox from 'vue-image-lightbox'
	require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

	export default {
		components: {
			LightBox
		},

		data: function() {
			return {
				media: [
					{ caption: 'NIHF STEM Middle school 2019', thumb: 'img/grants/slideshow/nihf_stem_middle_school_2019.png', src: 'img/grants/slideshow/nihf_stem_middle_school_2019.png' },
					{ caption: 'NIHF STEM Middle school 2017', thumb: 'img/grants/slideshow/nihf_stem_middle_school_2017.png', src: 'img/grants/slideshow/nihf_stem_middle_school_2017.png' },
					{ caption: 'Black River High School', thumb: 'img/grants/slideshow/black_river_high_school.png', src: 'img/grants/slideshow/black_river_high_school.png' },
					{ caption: 'Brecksville-Broadview Heights 2015', thumb: 'img/grants/slideshow/brecksville_broadview_hts_2015.png', src: 'img/grants/slideshow/brecksville_broadview_hts_2015.png' },
					{ caption: 'Liberty High School 2019', thumb: 'img/grants/slideshow/liberty_high_school_2019.png', src: 'img/grants/slideshow/liberty_high_school_2019.png' }
				]
			};
		},

		methods: {
			slideShow(e, index) {
				e.preventDefault();
				this.$refs.lightbox.showImage(index);
			}
		}
	}
</script>