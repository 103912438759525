<template>
	<div class="gr-border container">
		<div class="row">
			<div class="col-12 text-center">
				<h2><strong>Sorry about that - the user with an email you have tried to signup already exists.</strong></h2>
				<p>{{login_option}}</p>
			</div>
			<div class="col-4 offset-4 mt-3 text-center">
				<p><router-link :to="{ name: 'signin' }" class="btn btn-yellow">Log in</router-link></p>
				<p>or</p>
			</div>
			<div class="col-4 offset-4">
				<p><router-link to="/" class="btn btn-yellow">Visit Homepage</router-link></p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				login_option: ''
			}
		},

		async mounted() {
			if (this.$route.query.conns) {
				const conn = this.$route.query.conns;
				if(conn === 'google-oauth2') {
					this.login_option = `Please login using Google option`;
				}
				if(conn === 'GoPass') {
					this.login_option = `Please login using Goodyear Employee option`;
				}
				if(conn === 'facebook') {
					this.login_option = `Please login using Facebook option`;
				}
				if(conn === 'windowslive') {
					this.login_option = `Please login using Microsoft Account option`;
				}
			}
		}
	}
</script>