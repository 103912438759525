<template>
    <div class="gr-border container">
        <div class="row">
            <div class="col-12 text-center mt-4 mb-4">
                <h2>Please sign up to see previous registrations.</h2>
            </div>
            <div class="col-4 offset-4 mt-4">
                <p><button @click="signUp" class="btn btn-yellow">Sign Up</button></p>
            </div>
        </div>
    </div>
</template>

<script>
import { getAuthInstance } from '@/auth';

export default {

    methods: {
        async signUp(e) {
            e.preventDefault();

            const authInstance = getAuthInstance();
            if (!authInstance.auth0Client) {
                await authInstance.initialize();
            }

            await authInstance.loginWithRedirect({ authorizationParams: { screen_hint: "signup" } });
        }
    }
}
</script>