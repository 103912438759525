<template>
	<div class="container gr-border">
		<div class="row">
			<div class="text-center gr-bak col-12">
				<h1 class="dk-blue upper"><strong>You have questions and we have answers</strong></h1>
			</div>
		</div>	
		<div class="text-center col-8 offset-2">
			<p>We have answers to your frequently asked questions regarding both Middle School STEM Career Day 2025 and High School STEM Career Day 2025 events.</p>
		</div>
		<br/>
		<div class="row mb-3">
			<div class="col-md-8 offset-md-2">
				<b-card>
					<div class="accordion" role="tablist">
						<h3>MIDDLE SCHOOL EVENT</h3>
						<!--Schedule/Location-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-1 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Schedule/Location
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-1-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Where?
										</span>
										<b-collapse id="accordion-1-1-inner" class="mt-2">
											<p>Firestone CLC <!--: 470 Castle Blvd Akron, OH 44313--></p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-1-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											When?
										</span>
										<b-collapse id="accordion-1-2-inner" class="mt-2">
											<p>Saturday April 5th, 2025</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-1-3-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What?
										</span>
										<b-collapse id="accordion-1-3-inner" class="mt-2">
											<p>Goodyear's STEM Career Day Middle School Event brings students together to have fun while highlighting many opportunities in STEM. Throughout the day, students will interact with Goodyear associates and other local companies to participate in a hands-on engineering activity, various STEM-related interactive activities, an informational fair, and more!</p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<!--Registration and Walk In's-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-2 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Registration and Walk In's
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-2-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											When can I register?
										</span>
										<b-collapse id="accordion-2-1-inner" class="mt-2">
											<p>Registration is open now!</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											How do I register?
										</span>
										<b-collapse id="accordion-2-2-inner" class="mt-2">
											<p>Students can go <router-link :to="{ name: 'register' }">here</router-link> to register. They will require parental permission to complete registration.</p>
											<p>Group leaders (Middle School Event only) can register <router-link :to="{ name: 'register' }">here</router-link>.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-3-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What are Groups? Will I be grouped with my friends?
										</span>
										<b-collapse id="accordion-2-3-inner" class="mt-2">
											<p>Groups are students that have selected to register together under a “group leader”. A group leader is a teacher, parent, or councilor. Groups will almost always get to spend the day doing the same activities. However, when groups larger than 30 students cannot share schedules, due to classroom size limitations. Group leaders, per your school guidelines, please plan for chaperones accordingly.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-4-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What is the Maximum group size?
										</span>
										<b-collapse id="accordion-2-4-inner" class="mt-2">
											<p>For registration purposes, group size is not limited. However, on the day of the event, larger groups will be split up into “squads” due to classroom size limitations. Group leaders please plan for chaperones accordingly.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-5-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What is a squad?
										</span>
										<b-collapse id="accordion-2-5-inner" class="mt-2">
											<p>Students are split into squads that will dictate their schedule for the day. All squads are led by a volunteer that will ensure the students find their location.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-6-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Will there be chaperones?
										</span>
										<b-collapse id="accordion-2-6-inner" class="mt-2">
											<p>Yes! Both groups and individual registered students will be assigned “squad leaders.” These leaders will guide the students through the day. They are in addition to any teachers, chaperones, and/or legal guardians that may wish to attend.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-7-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											How do I join a group?
										</span>
										<b-collapse id="accordion-2-7-inner" class="mt-2">
											<p>Your group leader must first create the group. The leader will then obtain a group ID and password that you can use to join. You may join a group during the registration process or after you register*.</p>
											<p>*If you did not have the group information when you registered, you may login to your registration and add this information.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-8-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											When do I need to complete my consent form? How do I complete it?
										</span>
										<b-collapse id="accordion-2-8-inner" class="mt-2">
											<p>Consent forms must be completed by a parent of legal guardian during online registration in order to attend the event.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-9-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Can I login after I register to check or change my status?
										</span>
										<b-collapse id="accordion-2-9-inner" class="mt-2">
											<p>Yes, you may use your email and the password sent to you to login and check your status or join a group.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-10-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What if I forgot my password?
										</span>
										<b-collapse id="accordion-2-10-inner" class="mt-2">
											<p>You may use the password reset option located on <router-link :to="{ name: 'signin' }">Sign In page</router-link>.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-11-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Can I register on the day of the event?
										</span>
										<b-collapse id="accordion-2-11-inner" class="mt-2">
											<p>Walk in registrations are welcome. However, a parent/guardian must be present to sign a consent form, or the student must bring a signed copy of the consent form. The consent form can be found <a href="/files/contact/2024-ms-paper-consent-form.pdf" target="_blank">here.</a></p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-2-12-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											How do I cancel my registration?
										</span>
										<b-collapse id="accordion-2-12-inner" class="mt-2">
											<p>Cancellations can be made by logging into the websites, and selecting the cancel my registration button.</p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<!--Who can attend?-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-3 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Who can attend?
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-3-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What grades can attend?
										</span>
										<b-collapse id="accordion-3-1-inner" class="mt-2">
											<p>Goodyear welcomes any young men and young women enrolled in grades six through eight to participate in this free event.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-3-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Can parents/teachers attend?
										</span>
										<b-collapse id="accordion-3-2-inner" class="mt-2">
											<p>Teachers, parents and guardians are welcome to accompany their students to the event.</p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<!--Food/Allergy Information-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-4 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Allergies and Other Accommodations
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-4-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Will food be provided?
										</span>
										<b-collapse id="accordion-4-1-inner" class="mt-2">
											<p>Yes, lunch will be provided.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-4-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What allergy information do you have?
										</span>
										<b-collapse id="accordion-4-2-inner" class="mt-2">
											<p>For allergy-free food requests or other accommodations, please contact us via the form below or email us at gystem_careerday@goodyear.com.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-4-3-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											How do I request an accommodation?
										</span>
										<b-collapse id="accordion-4-3-inner" class="mt-2">
											<p>To request special accommodations during the event, please email us at gystem_careerday@goodyear.com</p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<h3>HIGH SCHOOL EVENT</h3>
						<!--Schedule/Location-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-5 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Schedule/Location
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-5-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Where?
										</span>
										<b-collapse id="accordion-5-1-inner" class="mt-2">
											<p>The University of Akron: Student Union, 303 Carroll St, Akron, OH 44325</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-5-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											When?
										</span>
										<b-collapse id="accordion-5-2-inner" class="mt-2">
											<p>Saturday February 8th, 2025</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-5-3-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What?
										</span>
										<b-collapse id="accordion-5-3-inner" class="mt-2">
											<p>Goodyear's STEM Career Day High School Event highlights career opportunities in STEM. Students will participate in presentations from professionals in the field, a Hands-On Activity, tours of the University of Akron and professional mentoring!</p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<!--Registration and Walk In's-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-6 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Registration and Walk In's
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-6-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											When can I register?
										</span>
										<b-collapse id="accordion-6-1-inner" class="mt-2">
											<p>Registration is open now!</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-6-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											How do I register?
										</span>
										<b-collapse id="accordion-6-2-inner" class="mt-2">
											<p>Students can go <router-link :to="{ name: 'register' }">here</router-link> to register. They will require parental permission to complete registration.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-6-3-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											When do I need to complete my consent form? How do I complete it?
										</span>
										<b-collapse id="accordion-6-3-inner" class="mt-2">
											<p>Consent forms must be completed by a parent of legal guardian during online registration in order to attend the event.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-6-4-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Can I login after I register to check my status?
										</span>
										<b-collapse id="accordion-6-4-inner" class="mt-2">
											<p>Yes, you may use your email and the password sent to you to login and check your registration.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-6-5-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What if I forgot my password?
										</span>
										<b-collapse id="accordion-6-5-inner" class="mt-2">
											<p>You may use the password reset option located on <router-link :to="{ name: 'signin' }">Sign In page</router-link>.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-6-6-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Can I register on the day of the event?
										</span>
										<b-collapse id="accordion-6-6-inner" class="mt-2">
											<p>Walk in registrations are welcome. However, a parent/guardian must be present to sign a consent form, or the student must bring a signed copy of the consent form. The consent form can be found <a href="/files/contact/2023-hs-paper-consent-form.pdf" target="_blank">here.</a></p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-6-7-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											How do I cancel my registration?
										</span>
										<b-collapse id="accordion-6-7-inner" class="mt-2">
											<p>Cancellations can be made by logging into the websites, and selecting the cancel my registration button. </p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>

						<!--Who can attend?-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-7 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Who can attend?
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-7-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What grades can attend?
										</span>
										<b-collapse id="accordion-7-1-inner" class="mt-2">
											<p>Goodyear welcomes any young men and young women enrolled in grades nine through twelve to participate in this free event.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-7-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Can parents/teachers attend?
										</span>
										<b-collapse id="accordion-7-2-inner" class="mt-2">
											<p>Teachers, parents and guardians are welcome to accompany their students to the event.</p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
                        <!--Food/Allergy Information-->
						<b-card no-body class="mb-1">
							<b-card-header header-tag="header" class="contact-collapsible p-1" role="tab">
								<b-button block v-b-toggle.accordion-8 class="accordion-header text-left">
									<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
									<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
									Allergies and Other Accommodations
								</b-button>
							</b-card-header>
							<b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>
										<span v-b-toggle.accordion-8-1-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											Will food be provided?
										</span>
										<b-collapse id="accordion-8-1-inner" class="mt-2">
											<p>Light refreshments are available, but lunch is not provided.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-8-2-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											What allergy information do you have?
										</span>
										<b-collapse id="accordion-8-2-inner" class="mt-2">
											<p>For allergy-free food requests or other accommodations, please contact us via the form below or email us at gystem_careerday@goodyear.com.</p>
										</b-collapse>
									</b-card-text>
									<b-card-text>
										<span v-b-toggle.accordion-8-3-inner class="accordion-header-inner">
											<b-icon icon="plus" scale="1.5" class="when-closed"></b-icon>
											<b-icon icon="dash" scale="1.5" class="when-open"></b-icon>
											How do I request an accommodation?
										</span>
										<b-collapse id="accordion-8-3-inner" class="mt-2">
											<p>To request special accommodations during the event, please email us at gystem_careerday@goodyear.com</p>
										</b-collapse>
									</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
					</div>
				</b-card>
			</div>
		</div>

		<div class="row mb-3" id="stem_contact_form">
			<div class="text-center col-8 offset-2">
				<p>If you’ve not met us yet, the Goodyear STEM Career Day planning team is a friendly bunch. We enjoy hearing from people who may be interested in learning more about this event. </p>
				<p><strong>Complete the fields below with more information so we can better address your questions.</strong></p>
			</div>
		</div>

		<ValidationObserver ref="form">
			<form>
				<div class="row">
					<div class="col-6 offset-3">
						<ValidationProvider rules="required" v-slot="{ classes, errors }">
							<div class="form-group">	
								<select class="form-control" :class="classes" v-model="formData.who">
									<option value="" disabled selected>I am a...</option>
									<option value="Student/Teacher/Parent">Student/Teacher/Parent</option>
									<option value="Member of the media">Member of the media</option>
									<option value="University">University</option>
									<option value="Company">Company</option>
									<option value="Sponsor">Sponsor</option>
									<option value="Other">Other</option>
								</select>
								<div class="invalid-feedback">{{ errors[0] }}</div>
							</div>
						</ValidationProvider>
						<ValidationProvider rules="required" v-slot="{ classes, errors }">
							<div class="form-group">
								<select class="form-control" :class="classes" v-model="formData.subject">
									<option value="" disabled selected>My question is regarding...</option>
									<option value="scholarships">Scholarships</option>
									<option value="grants">Grants</option>
									<option value="hs-chaperone">High School Event Chaperone</option>
									<option value="hs-reg">High School Registration</option>
									<option value="hs-day-of-details">High School Day-of-details</option>
									<option value="ms-reg">Middle School Registration</option>
									<option value="ms-day-of-details">Middle School Day-of-details</option>
									<option value="media-coverage">Media Coverage</option>
									<option value="sponsorship">Sponsorship</option>
									<option value="other">Other</option>
								</select>
								<div class="invalid-feedback">{{ errors[0] }}</div>
							</div>
						</ValidationProvider>
						<ValidationProvider rules="required|email" v-slot="{ classes, errors }">
							<div class="form-group">
								<input type="email" class="form-control" :class="classes" placeholder="My email address is" v-model="formData.email">
								<div class="invalid-feedback">{{ errors[0] }}</div>
							</div>
						</ValidationProvider>
						<textarea class="form-control mb-3" rows="4" placeholder="More information here" v-model="formData.question"></textarea>
						<div>
							<vue-recaptcha :loadRecaptchaScript="true" @verify="onVerify" :sitekey="recaptchaClientKey"></vue-recaptcha>
							<p class="text-danger mt-1 text-center">{{invalidField}}</p>
						</div>
						<button type="button" value="Submit" class="form-control btn btn-yellow" @click="sendQuestion" title="Submit">Submit</button>
					</div>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
	import dataService from '../services/data.service';
	import { VueRecaptcha } from 'vue-recaptcha';
	import { config } from '../../config'

	export default {
		components: { VueRecaptcha },
		computed: {
			recaptchaClientKey: function () {
				return config.RECAPTCHA_CLIENT_KEY;
			}
		},
		data: function() {
			return {
				formData: {
					who: "",
					subject: "",
					email: "",
					question: "",
					gResponse: "",
				},
				invalidField: ""
			};
		},

		mounted() {
			if(this.$route.query.accordionId) {
				this.$root.$emit('bv::toggle::collapse', this.$route.query.accordionId);
			}
		},

		methods: {
			onVerify: function (response) {
				if (response) {
					this.invalidField = "";
					this.formData.gResponse = response;
				} 
			},
			sendQuestion: async function () {
				try {
					let isFormValid = await this.$refs.form.validate();
					if (isFormValid) {
						if (this.formData.gResponse.length !== 0) {
							await dataService.sendQuestion(this.formData);
							this.$router.push({ name: 'contact-thank-you' });
						} else {
							this.invalidField = 'Please verify using reCAPTCHA.'
						}
					}
				} catch (error) {
					if(error.response && error.response.data) {
						if(error.response.data.code == -4) {
							this.invalidField = error.response.data.message;
							return;
						}
					}
					console.log(error);
					this.invalidField = "Sorry! Something went wrong."
				}
			}
		}
	}
</script>
