import moment from 'moment'

export default {
  regType: "HSIND",
  firstName: "",
  lastName: "",
  email: "",
  schoolId: "",
  needTranslator: "",
  specifiedLanguage: "",
  otherSchoolName: "",
  gradeLevelId: "",
  tShirtSizeId: "",
  hasAttended: "",
  tourType: {
    aerospaceEngineering: 0,
    biology: 0,
    chemistry: 0,
    construction: 0,
    computer: 0,
    electricalEngineering: 0,
    geosciences: 0,
    appliedMathematics: 0,
    physics: 0,
    medicine: 0,
    plastics: 0,
    eSports: 0,
    honorsCollege: 0,
    varsityESports: 0,
    stemInSports: 0,
    nursingSimulations: 0,
    chemicalTransformations: 0,
    engineeringDesign: 0
  },
  parentGuardian: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    willBeAttending: "",
    willAddParentBeAttending: "",
    addParentFirstName: "",
    addParentLastName: ""
  },
  emergencyContact: {
    firstName: "",
    lastName: "",
    phoneNumber: ""
  },
  consentWillBeSubmittedBySchool: "",
  stemDayConsent: {
    parentGuardianName: "",
    relation: "",
    studentName: "",
    parentGuardianElSignature: "",
    date: moment().format('MM/DD/YYYY')
  }
}