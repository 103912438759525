import moment from 'moment'

export default {
  regType: "HSDAYOFCONSENT",
  stemDayConsent: {
    parentGuardianName: "",
    relation: "",
    studentName: "",
    parentGuardianElSignature: "",
    date: moment().format('MM/DD/YYYY')
  }
}